import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { PayPalButton } from 'react-paypal-button-v2'
import Loader from './Loader'

const ServerSidePayPal = (props) => {
  console.log(props.orderID)
  const [amount, setAmount] = useState()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    async function fetchData() {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
            'Content-Type': 'multipart/form-data',
          },
        }

        const { data } = await axios.get(
          `/api/orders/orderinfo/${props.orderID}`,
          config
        )
        setAmount(data)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [])

  const showButton = () => {
    if (amount > 0) {
      return (
        <PayPalButton
          currency={'GBP'}
          onSuccess={props.successPaymentHandler}
          amount={amount}
          shippingPreference='NO_SHIPPING'
        />
      )
    } else {
      return <Loader />
    }
  }

  return showButton()
}

export default ServerSidePayPal
