import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {
  variantsProducts,
} from '../../redux/actions/variantsActions'
import VariantsTable from '../../components/dataTables/VariantsTable'

const VariantListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const variantList = useSelector((state) => state.variantList)
  const { loading, error, variants } = variantList

  const variantDelete = useSelector((state) => state.variantDelete)
  const { success: deleteSuccess } = variantDelete

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(variantsProducts())
    } else {
      history.push('/')
    }
  }, [dispatch, userInfo, history, deleteSuccess])

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>VARIANTS</h1>
        </Col>
        <Col className='text-right'>
          <LinkContainer to={`/admin/variants/create`}>
            <Button className='my-3'>Create VARIANTS</Button>
          </LinkContainer>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <VariantsTable data={variants} />
      )}
    </>
  )
}

export default VariantListScreen
