import React, { useState } from 'react'
import { Button, Modal, Row, Col, Image } from 'react-bootstrap'

const SelectMediaImage = ({
  image,
  count,
  updateSelected,
  currentlySelected,
}) => {
  let cssClasses = ''

  if (currentlySelected == count) {
    cssClasses = 'media-library-selected'
  }

  const isSelected = () => {
    updateSelected(count, image)
  }

  return (
    <div onClick={isSelected} className={cssClasses}>
      <Image src={image} fluid />
    </div>
  )
}

export default SelectMediaImage
