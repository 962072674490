import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, Image } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listLatestProducts } from '../redux/actions/productActions'
import HomePageCarousel from '../components/HomePageCarousel'
import Categories from '../components/categories'
import { getBaseCategories } from '../redux/actions/categoriesActions'

const HomeScreen = () => {
  const dispatch = useDispatch()

  const categoryList = useSelector((state) => state.categories)
  const { categories, loading, error } = categoryList

  useEffect(() => {
    dispatch(listLatestProducts())
    dispatch(getBaseCategories())
  }, [dispatch])

  return (
    <>
      <Image
        src='https://jkclothing-images.s3.eu-west-2.amazonaws.com/products/jk%20logo%202024%20%281%29_pages-to-jpg-0001-1709115884686.jpg'
        className='mx-auto d-block img-fluid'
      />
      <HomePageCarousel />
      {/* <h1>Latest Products</h1> */}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Row>
            {categories.map((category) => (
              <Col
                className='align-items-stretch d-flex'
                key={category._id}
                sm={12}
                md={6}
                lg={4}
                xl={3}
              >
                <Categories category={category} />
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  )
}

export default HomeScreen
