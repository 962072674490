import {
  GET_SETTINGS_FAIL,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
} from '../types'

export const settingsReducer = (state = { settings: [] }, action) => {
  switch (action.type) {
    case GET_SETTINGS_REQUEST:
      return { loading: true, settings: [] }
    case GET_SETTINGS_SUCCESS:
      return { loading: false, settings: action.payload }
    case GET_SETTINGS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
