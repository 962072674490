import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../../components/FormContainer'
import {
  getParentCategories,
} from '../../../redux/actions/categoriesActions'

const HomePageCategoriesScreen = ({ history }) => {
  const dispatch = useDispatch()

  const [inputList, setInputList] = useState([])

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const categoriesParent = useSelector((state) => state.categoriesParents)
  const { categoriesParentList: parents } = categoriesParent


  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(getParentCategories())
    } else {
      history.push('/')
    }
  }, [dispatch, userInfo, history])

  const submitHandler = (e) => {
    e.preventDefault()
  }

  return (
    <>
     <Link to='/admin/settings/home' className='btn btn-light my-3'>
        Go Back
      </Link>
       <FormContainer>
       <h1>Home Page Categoreis</h1>
       <Form onSubmit={submitHandler}>
       <Form.Group controlId='parent'>
        <Row>
              <Form.Control
                as='select'
                // onChange={(e) => setParent(e.target.value)}
                // value={parent}
              >
                <option value='none'>None</option>
                {parents &&
                  parents.map((option) => (
                    <option key={option.slug} value={option.slug}>
                      {option.name}
                    </option>
                  ))}
              </Form.Control>
              <Button
                variant='primary'
                // onClick={() => {
                //   handleAddClick()
                // }}
              >
                Add Row
              </Button>
              </Row>
            </Form.Group>
      </Form>
       </FormContainer>
    </>
  )
}

export default HomePageCategoriesScreen