import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../components/FormContainer'
import {
  getVariantDetails,
  updateVariant,
} from '../../redux/actions/variantsActions'
import { VARIANT_UPDATE_RESET } from '../../redux/types'

const VariantsEditScreen = ({ history, match }) => {
  const variantsId = match.params.id
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [inputList, setInputList] = useState([])

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const variantDetials = useSelector((state) => state.variantDetials)
  const { variant } = variantDetials

  const variantUpdate = useSelector((state) => state.variantUpdate)
  const { success } = variantUpdate

  useEffect(() => {
    if (success) {
      dispatch({
        type: VARIANT_UPDATE_RESET,
      })
      history.push('/admin/variants')
    } else {
      if (userInfo && userInfo.isAdmin) {
        if (!variant.name || variant._id !== variantsId) {
          dispatch(getVariantDetails(variantsId))
        } else {
          setInputList(variant.varintItems)
          setName(variant.name)
        }
      }
    }
  }, [dispatch, userInfo, history, variant, success])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateVariant({ id: variantsId, name: name, varintItems: inputList })
    )
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { name: '', order: '' }])
  }

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  return (
    <>
      <Link to='/admin/variants' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit variant</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name'>
            <Form.Label>Variant Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter variant name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          {inputList.map((variant, i) => (
            <Form.Group key={variant + i}>
              <Row>
                <Col>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type='name'
                    name='name'
                    placeholder='Enter name'
                    value={variant.name}
                    onChange={(e) => handleInputChange(e, i)}
                  ></Form.Control>
                </Col>
                <Col>
                  <Form.Label>Order</Form.Label>
                  <Form.Control
                    type='number'
                    name='order'
                    placeholder='Enter Order'
                    value={variant.order}
                    onChange={(e) => handleInputChange(e, i)}
                  ></Form.Control>
                </Col>
                <Col>
                  <Button
                    variant='primary'
                    onClick={() => {
                      handleRemoveClick(i)
                    }}
                    style={{ marginTop: '25px' }}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          ))}
          <Row>
            <Col md={8}>
              <Button
                variant='primary'
                onClick={() => {
                  handleAddClick()
                }}
              >
                Add Row
              </Button>
            </Col>
            <Col>
              <Button type='submit' variant='primary'>
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </FormContainer>
    </>
  )
}

export default VariantsEditScreen
