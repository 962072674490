import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'
import { Row, Col, ListGroup, Image, Form, Button, Card } from 'react-bootstrap'
import Message from '../components/Message'
import {
  addItemServerSideBasket,
  removeItemServerSideBasket,
  getServerSideBasket,
  increaseCartItemServerSideBasket,
} from '../redux/actions/cartAction'
import CheckoutSteps from '../components/CheckoutSteps'

const CartScreen = ({ match, location, history }) => {
  var cartID = ''
  const productId = match.params.id
  const urlParams = decodeURIComponent(location.search.substring(1))

  const pairParams = location.search
    ? JSON.parse(
        '{"' + urlParams.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === '' ? value : decodeURIComponent(value)
        }
      )
    : ''
  const BasketID = Math.random().toString(32).slice(-4)

  const qty = pairParams ? Number(pairParams.qty) : 1
  const variantID = pairParams ? pairParams.v : 0

  const dispatch = useDispatch()

  const substringText = pairParams
  delete substringText.qty
  if (variantID !== 0) {
    delete substringText.v
  }

  const serverCart = useSelector((state) => state.serverCart)
  const { orderItems: serverCartItems, loading } = serverCart

  useEffect(() => {
    if (productId) {
      dispatch(
        addItemServerSideBasket(
          productId,
          qty,
          variantID,
          substringText,
          BasketID
        )
      )
    }
  }, [dispatch, productId, qty, variantID])

  const removeFromCartHandler = (id) => {
    dispatch(removeItemServerSideBasket(cartID, id))
  }

  const increaseItemAmount = (item, amount) => {
    //cardID, BasketID, amount
    dispatch(increaseCartItemServerSideBasket(cartID, item.BasketID, amount))
  }

  const checkoutHandler = () => {
    history.push('/login?redirect=shipping')
  }

  const ShoppingBasketContainer = () => {
    if (serverCartItems?.length === 0) {
      return <Message>Your cart is empty</Message>
    } else {
      if (loading) {
        return <Loader />
      } else {
        return (
          <ListGroup variant='flush'>
            {serverCartItems?.map((item) => (
              <ListGroup.Item key={item.BasketID}>
                <Row>
                  <Col md={2}>
                    <Image
                      src={item.image}
                      alt={item.name}
                      fluid
                      rounded
                    ></Image>
                  </Col>
                  <Col md={3}>
                    <Link to={`/product/${item.product}`}>{item.name}</Link>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: decodeURIComponent(item.subtext),
                      }}
                    ></p>
                  </Col>
                  <Col md={2}>£{item.price.toFixed(2)}</Col>
                  <Col md={2}>
                    <Form.Control
                      as='input'
                      type='number'
                      min='1'
                      value={item.qty}
                      onChange={(e) =>
                        increaseItemAmount(item, Number(e.target.value))
                      }
                    ></Form.Control>
                  </Col>
                  <Col md={2}>
                    <Button
                      type='button'
                      variant='light'
                      onClick={() =>
                        removeFromCartHandler(item.BasketID, cartID)
                      }
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )
      }
    }
  }

  return (
    <>
      <CheckoutSteps step1 />
      <Row>
        <Col md={8}>
          <h1>Shopping Cart</h1>
          {ShoppingBasketContainer()}
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>
                  Subtotal (
                  {serverCartItems?.reduce((acc, item) => acc + item.qty, 0)})
                  items
                </h2>
                £
                {serverCartItems
                  ?.reduce((acc, item) => acc + item.price * item.qty, 0)
                  .toFixed(2)}
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  type='button'
                  className='btn-block'
                  disabled={serverCartItems?.length > 0 ? false : true}
                  onClick={checkoutHandler}
                >
                  Proceed to checkout
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default CartScreen
