import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import CategoryScreen from './screens/CategoryScreen'
import DisplayProductsScreen from './screens/DisplayProductsScreen'
import ProfileScreen from './screens/ProfileScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import ShippingTypeScreen from './screens/ShippingTypeScreen'
import OrderScreen from './screens/OrderScreen'
import ReturnsPolicyScreen from './screens/ReturnsPolicyScreen'
import DeliveryInformationScreen from './screens/DeliveryInformationScreen'
import TermsConditionsScreen from './screens/TermsConditionsScreen'
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen'
import ContactScreen from './screens/ContactScreen'
//admin screens
import UserListScreen from './screens/admin/UserListScreen'
import UserEditScreen from './screens/admin/UserEditScreen'
import ProductListScreen from './screens/admin/ProductListScreen'
import ProductEditScreen from './screens/admin/ProductEditScreen'
import CategoryListScreen from './screens/admin/CategoryListScreen'
import CategoryCreateScreen from './screens/admin/CategoryCreateScreen'
import OrderListScreen from './screens/admin/OrderListScreen'
import variantListScreen from './screens/admin/variantListScreen'
import variantsCreateScreen from './screens/admin/variantsCreateScreen'
import variantsEditScreen from './screens/admin/variantsEditScreen'
import CategoryEditScreen from './screens/admin/CategoryEditScreen'
//admin settings screens
import SettingsHome from './screens/admin/settings/settingsHome'
import carouselImages from './screens/admin/settings/carouselImages'
import EditReturnsPolicyScreen from './screens/admin/settings/EditReturnsPolicyScreen'
import EditDeliveryInformationScreen from './screens/admin/settings/EditDeliveryInformationScreen'
import EditTermsConditionsScreen from './screens/admin/settings/EditTermsConditionsScreen'
import EditPrivacyPolicyScreen from './screens/admin/settings/EditPrivacyPolicyScreen'
import HomePageCategoriesScreen from './screens/admin/settings/HomePageCategoriesScreen'

const App = () => {
  return (
    <Router>
      <Header />
      <main className='py-5'>
        <Container>
          <Route path='/search/:keyword' component={HomeScreen} />
          <Route path='/' component={HomeScreen} exact />
          <Route path='/product/:id' component={ProductScreen} />
          <Route path='/cart/:id?' component={CartScreen} />
          <Route path='/login' component={LoginScreen} />
          <Route path='/register' component={RegisterScreen} />
          <Route path='/category/:slug?' component={CategoryScreen} />
          <Route path='/products/:slug?' component={DisplayProductsScreen} />
          <Route path='/profile' component={ProfileScreen} />
          <Route path='/shipping' component={ShippingScreen} />
          <Route path='/payment' component={PaymentScreen} />
          <Route path='/placeorder' component={PlaceOrderScreen} />
          <Route path='/shippingtypes' component={ShippingTypeScreen} />
          <Route path='/order/:id' component={OrderScreen} />
          <Route path='/admin/userlist' component={UserListScreen} />
          <Route path='/admin/products' component={ProductListScreen} />
          <Route path='/admin/orders' component={OrderListScreen} />
          <Route path='/returns-policy' component={ReturnsPolicyScreen} />
          <Route path='/contact' component={ContactScreen} />
          <Route
            path='/delivery-information'
            component={DeliveryInformationScreen}
          />
          <Route
            path='/terms-and-conditions'
            component={TermsConditionsScreen}
          />
          <Route path='/privacy-policy' component={PrivacyPolicyScreen} />
          <Route
            path='/admin/variants/create'
            component={variantsCreateScreen}
          />
          <Route
            path='/admin/variants/:id/edit'
            component={variantsEditScreen}
          />
          <Route exact path='/admin/variants' component={variantListScreen} />
          <Route path='/admin/product/:id/edit' component={ProductEditScreen} />
          <Route path='/admin/user/:id/edit' component={UserEditScreen} />
          <Route
            exact
            path='/admin/categories'
            component={CategoryListScreen}
          />
          <Route
            path='/admin/categories/create'
            component={CategoryCreateScreen}
          />
          <Route
            path='/admin/categories/:id/edit'
            component={CategoryEditScreen}
          />
          <Route path='/admin/settings/home' component={SettingsHome} />
          <Route
            path='/admin/settings/carousel-images'
            component={carouselImages}
          />
          <Route
            path='/admin/settings/returnspolicy'
            component={EditReturnsPolicyScreen}
          />
          <Route
            path='/admin/settings/deliveryinformation'
            component={EditDeliveryInformationScreen}
          />
          <Route
            path='/admin/settings/termsconditions'
            component={EditTermsConditionsScreen}
          />
          <Route
            path='/admin/settings/privacypolicy'
            component={EditPrivacyPolicyScreen}
          />
           <Route
            path='/admin/settings/homepageCategories'
            component={HomePageCategoriesScreen}
          />
        </Container>
      </main>
      <Footer />
    </Router>
  )
}

export default App
