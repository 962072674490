import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

const categories = ({ category }) => {
  let pageMarkup
  if (category.external) {
    pageMarkup = (
      <Card className='my-3 p-3 rounded'>
        <Link to={{ pathname: category.externalLink }} target='_blank'>
          <Card.Img src={category.image} variant='top' />
        </Link>
        <Card.Body>
          <Link to={{ pathname: category.externalLink }} target='_blank'>
            <Card.Title className='text-center' as='div'>
              <h2>{category.name}</h2>
            </Card.Title>
          </Link>
        </Card.Body>
      </Card>
    )
  } else {
    pageMarkup =
      category.displayType === 'products' ? (
        <Card className='my-3 p-3 rounded'>
          <Link to={`/products/${category.slug}`}>
            <Card.Img src={category.image} variant='top' />
          </Link>
          <Card.Body>
            <Link to={`/products/${category.slug}`}>
              <Card.Title className='text-center' as='div'>
                <h2>{category.name}</h2>
              </Card.Title>
            </Link>
          </Card.Body>
        </Card>
      ) : (
        <Card className='my-3 p-3 rounded'>
          <Link to={`/category/${category.slug}`}>
            <Card.Img src={category.image} variant='top' />
          </Link>
          <Card.Body>
            <Link to={`/category/${category.slug}`}>
              <Card.Title className='text-center' as='div'>
                <h2>{category.name}</h2>
              </Card.Title>
            </Link>
          </Card.Body>
        </Card>
      )
  }
  return <>{pageMarkup}</>
}

export default categories
