import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import Product from '../components/Product'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listProductsFromSlug } from '../redux/actions/productActions'

const DisplayProductsScreen = ({ match }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  })

  const dispatch = useDispatch()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products } = productList

  useEffect(() => {
    dispatch(listProductsFromSlug(match.params.slug))
  }, [dispatch, match.params.slug])

  return (
    <>
      <h1>Products</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : products.length === 0 ? (
        <Message>This category currently has no products</Message>
      ) : (
        <Row>
          {products.map((product) => (
            <Col
              className='align-items-stretch d-flex'
              key={product._id}
              sm={12}
              md={6}
              lg={4}
              xl={3}
            >
              <Product product={product} category={match.params.slug} />
            </Col>
          ))}
        </Row>
      )}
    </>
  )
}

export default DisplayProductsScreen
