import React, { useEffect, useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {
  listProducts,
  deleteProduct,
  createProduct,
} from '../../redux/actions/productActions'
import { PRODUCT_CREATE_RESET } from '../../redux/types'
import DeleteProdcut from '../../components/modals/DeleteProduct.js'
import ProductsTable from '../../components/dataTables/ProductsTable.js'

const ProductListScreen = ({ history, location }) => {
  const dispatch = useDispatch()
  const urlParams = location.search.substring(1)

  const pairParams = location.search
    ? JSON.parse(
        '{"' + urlParams.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === '' ? value : decodeURIComponent(value)
        }
      )
    : ''

  let reloadpage = pairParams.reload

  const [loadedProducts, setLoadedProducts] = useState()

  const [filterType, setFilterType] = useState('name')

  const [loadedProductsRequest, setLoadedProductsRequest] = useState(false)

  const productList = useSelector((state) => state.productList)
  let { loading, error, products } = productList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productDelete = useSelector((state) => state.productDelete)
  const { success: deleteSuccess } = productDelete

  const productCreate = useSelector((state) => state.productCreate)
  const {
    success: createSuccess,
    product: createdProduct,
    loading: createLoading,
    error: createError,
  } = productCreate

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET })
    if (!userInfo.isAdmin) {
      history.push('/')
    }
    if (reloadpage == 'true') {
      dispatch(listProducts())
      history.push(`/admin/products`)
    }
    if (createSuccess) {
      history.push(`/admin/product/${createdProduct._id}/edit`)
    } else {
      if (products.length === 0 && loadedProductsRequest === false) {
        setLoadedProductsRequest(true)
        dispatch(listProducts())
      } else {
        setLoadedProducts(products)
      }
    }
  }, [
    dispatch,
    userInfo,
    history,
    deleteSuccess,
    createSuccess,
    createdProduct,
    products,
    setLoadedProducts,
    reloadpage,
  ])

  const createProductHandler = () => {
    dispatch(createProduct())
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Products</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={createProductHandler}>
            Create Product
          </Button>
        </Col>
      </Row>
      {createLoading && <Loader />}
      {createError && <Message variant='danger'>{createError}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
         <ProductsTable data={products} />
        </>
      )}
    </>
  )
}
export default ProductListScreen
