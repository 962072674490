import React, { useEffect, useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {
  getallCategories,
  deleteCategory,
} from '../../redux/actions/categoriesActions'
import CategoriesTable from '../../components/dataTables/CategoriesTable'

const CategoryListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const categoriesList = useSelector((state) => state.categoriesList)
  const { categoriesList: categories, loading, error } = categoriesList

  const categoriesDelete = useSelector((state) => state.categoriesDelete)
  const { success: deleteSuccess } = categoriesDelete

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(getallCategories())
    } else {
      history.push('/')
    }
  }, [dispatch, userInfo, history, deleteSuccess])

  const deleteHandler = (cId) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteCategory(cId))
    }
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Categories</h1>
        </Col>
        <Col className='text-right'>
          <LinkContainer to={`/admin/categories/create`}>
            <Button className='my-3'>Create Category</Button>
          </LinkContainer>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
       <CategoriesTable data={categories} />
      )}
    </>
  )
}

export default CategoryListScreen
