import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {
  const settings = useSelector((state) => state.settings)
  const { settings: siteSettings } = settings

  const d = new Date()
  let year = d.getFullYear()

  return (
    <footer className='footerBar'>
      <Container>
        <Row>
          <Col className='text-center py-2'>
            <Row className='justify-content-center py-2'>
              <h5>CONTACT US</h5>
            </Row>
            <Row className='pb-2'>
              <Col md={{ span: 2, offset: 3 }}>
                <i class='fas fa-phone fa-2x'></i>
              </Col>
              <Col md={6} className='text-left'>
                <ul className='footerPhone'>
                  <li>Eltham: 0208 859 4004</li>
                  <li>Blackfen: 0208 303 3136</li>
                </ul>
              </Col>
            </Row>
            <Row className='pb-2'>
              <Col md={{ span: 2, offset: 3 }}>
                <i class='fas fa-envelope fa-2x'></i>
              </Col>
              <Col md={6} className='text-left'>
                sales@jkclothing.co.uk
              </Col>
            </Row>
          </Col>
          <Col className='text-center py-2'>
            <Row className='justify-content-center py-2'>
              <h5>CUSTOMER SERVICES</h5>
            </Row>
            <Row className='justify-content-center pb-2'>
              <Link to='/returns-policy'>Returns Policy </Link>
            </Row>
            <Row className='justify-content-center pb-2'>
              <Link to='/delivery-information'>Delivery Information</Link>
            </Row>
            <Row className='justify-content-center pb-2'>
              <Link to='/terms-and-conditions'>Terms and Conditions </Link>
            </Row>
            <Row className='justify-content-center pb-2'>
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className='text-center py-3'>
            Copyrite &copy; {siteSettings[0] && siteSettings[0].siteName} {year}
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
