import React, { useState, useEffect, useRef } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Form,  Pagination} from 'react-bootstrap'
import OrderQuickView from '../modals/OrderQuickView';

const OrdersTable = ({data}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const [filteredData, setFilteredData] = useState(data);
    const [paginatedData, setPaginatedData] = useState([]);

    const searchBox = useRef()

    const filterTable = (value) => {
        var filteredCopy = [...data]
        filteredCopy = filteredCopy.filter((order) =>
          order.name.name.toLowerCase().includes(value.toLowerCase())
        )
        setFilteredData(filteredCopy)
      }

      const resetFilter = () => {
        setPaginatedData(data)
        searchBox.current.value = null
      }
  
    useEffect(() => {
      const updatePaginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setPaginatedData(filteredData.slice(startIndex, endIndex));
      };
  
      updatePaginatedData();
    }, [currentPage, filteredData, itemsPerPage]);
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    const renderPaginationItems = () => {
      const items = [];
      const totalPages = Math.ceil(filteredData.length / itemsPerPage);
      const maxPagesToShow = 5;
      let startPage, endPage;
  
      if (totalPages <= maxPagesToShow) {
        startPage = 1;
        endPage = totalPages;
      } else {
        const middlePage = Math.floor(maxPagesToShow / 2);
        if (currentPage <= middlePage) {
          startPage = 1;
          endPage = maxPagesToShow;
        } else if (currentPage + middlePage >= totalPages) {
          startPage = totalPages - maxPagesToShow + 1;
          endPage = totalPages;
        } else {
          startPage = currentPage - middlePage;
          endPage = currentPage + middlePage;
        }
      }
  
      if (startPage > 1) {
        items.push(
          <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
            1
          </Pagination.Item>
        );
        if (startPage > 2) {
          items.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }
      }
  
      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
  
      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          items.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }
        items.push(
          <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </Pagination.Item>
        );
      }
  
      return items;
    };

    const prettyDate = (date) => {
        return new Date(date).toLocaleDateString('en-GB')
      }

    
  return (
    <>
    <Row className='my-1'>
      <Col md={10}>
        <Form.Control
          ref={searchBox}
          type='text'
          placeholder='Enter name'
          onChange={(e) => filterTable(e.target.value)}
        />
      </Col>
      <Col md={2}>
        <Button onClick={resetFilter}>Reset</Button>
      </Col>
    </Row>
    <Table striped bordered hover responsive className='table-sm'>
      <thead>
        <tr>
          <th>ID</th>
          <th>NAME</th>
          <th>ORDER DATE</th>
          <th>TOTAL ORDER PRICE</th>
          <th>PAID</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {paginatedData.map((order) => (
          <tr key={order._id}>
            <td>
              {order._id}
              <OrderQuickView order={order} />
            </td>
            <td>{order?.name?.name}</td>
            <td>{prettyDate(order?.createdAt)}</td>
            <td>£{order.totalPrice.toFixed(2)}</td>
            <td>
              {' '}
              {order.isPaid ? (
                <i className='fas fa-check' style={{ color: 'green' }}></i>
              ) : (
                <i className='fas fa-times' style={{ color: 'red' }}></i>
              )}
            </td>
            <td>
              <LinkContainer to={`/order/${order._id}`}>
                <Button variant='light' className='btn-sm'>
                  view
                </Button>
              </LinkContainer>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} />
        <Pagination.Prev
          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
        />
        {renderPaginationItems()}
        <Pagination.Next
          onClick={() => handlePageChange(Math.min(Math.ceil(filteredData.length / itemsPerPage), currentPage + 1))}
        />
        <Pagination.Last onClick={() => handlePageChange(Math.ceil(filteredData.length / itemsPerPage))} />
      </Pagination>
  </>
  )
}

export default OrdersTable