import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Form,  Pagination} from 'react-bootstrap'
import { useDispatch  } from 'react-redux'
import DeleteProduct from '../modals/DeleteProduct.js'

const ProductsTable = ({data}) => {
   
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [paginatedData, setPaginatedData] = useState([]);

    const [filterType, setFilterType] = useState('name')
  
    useEffect(() => {
      const updatePaginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setPaginatedData(filteredData.slice(startIndex, endIndex));
      };
  
      updatePaginatedData();
    }, [currentPage, filteredData, itemsPerPage]);
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    const renderPaginationItems = () => {
      const items = [];
      const totalPages = Math.ceil(filteredData.length / itemsPerPage);
      const maxPagesToShow = 5;
      let startPage, endPage;
  
      if (totalPages <= maxPagesToShow) {
        startPage = 1;
        endPage = totalPages;
      } else {
        const middlePage = Math.floor(maxPagesToShow / 2);
        if (currentPage <= middlePage) {
          startPage = 1;
          endPage = maxPagesToShow;
        } else if (currentPage + middlePage >= totalPages) {
          startPage = totalPages - maxPagesToShow + 1;
          endPage = totalPages;
        } else {
          startPage = currentPage - middlePage;
          endPage = currentPage + middlePage;
        }
      }
  
      if (startPage > 1) {
        items.push(
          <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
            1
          </Pagination.Item>
        );
        if (startPage > 2) {
          items.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }
      }
  
      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
  
      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          items.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }
        items.push(
          <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </Pagination.Item>
        );
      }
  
      return items;
    };



  const filterTable = (value) => {
    if (filterType == 'name') {
      var filteredCopy = [...data]
      filteredCopy = filteredCopy.filter((product) =>
        product.name.toLowerCase().includes(value.toLowerCase())
      )
      console.log(filteredCopy)
      setFilteredData(filteredCopy)
    } else if (filterType == 'category') {
      var filteredCopy = [...data]
      filteredCopy = filteredCopy.filter((product) => {
        return product.category.some((category) =>
          category.name
            .replace(/-/g, ' ')
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      })
      setFilteredData(filteredCopy)
    }
  }

  return (
   <>
    <Form.Label>Search products</Form.Label>
    <Row className='my-1'>
            <Col md={6}>
              <Form.Control
                type='text'
                placeholder='Enter product name'
                onChange={(e) => filterTable(e.target.value)}
              />
            </Col>
            <Col md={3}>
              <Form.Control
                as='select'
                onChange={(e) => setFilterType(e.target.value)}
              >
                <option value='name'>Name</option>
                <option value='category'>Category</option>
              </Form.Control>
            </Col>
            <Col md={3}>
              {/* <Button onClick={resetFilteredList}>Reset view</Button> */}
            </Col>
          </Row>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>NAME</th>
                <th>STOCK</th>
                <th>Price</th>
                <th>Published</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {paginatedData && paginatedData.map((product) => (
                  <tr key={product._id}>
                    <td>{product.name}</td>
                    <td>
                      {product.countInStock && product.countInStock > 0 ? (
                        <strong style={{ color: '#7ad03a', fontWeight: '900' }}>
                          {product.countInStock}
                        </strong>
                      ) : (
                        <strong style={{ color: 'red', fontWeight: '900' }}>
                          {product.countInStock}
                        </strong>
                      )}
                    </td>
                    <td>£{product.price.toFixed(2)}</td>
                    <td>
                      {' '}
                      {product.publishState ? (
                        <i
                          className='fas fa-check'
                          style={{ color: 'green' }}
                        ></i>
                      ) : (
                        <i
                          className='fas fa-times'
                          style={{ color: 'red' }}
                        ></i>
                      )}
                    </td>
                    <td>
                      <LinkContainer to={`/admin/product/${product._id}/edit`}>
                        <Button variant='light' className='btn-sm'>
                          <i className='fas fa-edit'></i>
                        </Button>
                      </LinkContainer>
                      <DeleteProduct props={product} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} />
        <Pagination.Prev
          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
        />
        {renderPaginationItems()}
        <Pagination.Next
          onClick={() => handlePageChange(Math.min(Math.ceil(filteredData.length / itemsPerPage), currentPage + 1))}
        />
        <Pagination.Last onClick={() => handlePageChange(Math.ceil(filteredData.length / itemsPerPage))} />
      </Pagination>
          </>
  )
}

export default ProductsTable