import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteProduct,
  listProducts,
} from '../../redux/actions/productActions.js'

const DeleteProduct = ({ props }) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => setShow(true)

  const drop = () => {
    dispatch(deleteProduct(props._id))
    setTimeout(function () {
      dispatch(listProducts())
    }, 500)
    setShow(false)
  }

  return (
    <>
      <Button variant='danger' className='btn-sm' onClick={handleShow}>
        <i className='fas fa-trash'></i>
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You will delete this product from the site, there is no way to
            revert this action.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='danger' onClick={drop}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteProduct
