import axios from 'axios'
import {
  GET_MEDIA_LIBRARY_FAIL,
  GET_MEDIA_LIBRARY_REQUEST,
  GET_MEDIA_LIBRARY_SUCCESS,
} from '../types'

export const getMedia = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    dispatch({ type: GET_MEDIA_LIBRARY_REQUEST })
    const { data } = await axios.get('/api/upload', config)
    dispatch({
      type: GET_MEDIA_LIBRARY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_MEDIA_LIBRARY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
