import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel, Image } from 'react-bootstrap'
import Loader from './Loader'

const HomePageCarousel = () => {
  const settings = useSelector((state) => state.settings)
  const { loading, settings: siteSettings } = settings

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Carousel pause='hover' className='bg-dark'>
          {siteSettings[0] &&
            siteSettings[0].homePageCarousel.map((image) => {
              return (
                <Carousel.Item>
                  <Image src={image} fluid />
                </Carousel.Item>
              )
            })}
        </Carousel>
      )}
    </>
  )
}

export default HomePageCarousel
