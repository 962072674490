import React, { useEffect } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import AdminOrderList from '../../components/adminOrderList'
import { getAllOrders, assignedOrders } from '../../redux/actions/orderActions'
import OrderStats from '../../components/orderStats'
import OrdersTable from '../../components/dataTables/OrdersTable'

const OrderListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const allOrders = useSelector((state) => state.allOrders)
  const { allOrders: allOrdersData, loading, error } = allOrders

  const assignedOrdersState = useSelector((state) => state.myOrderList)
  const {
    orders: myAssignedOrders,
    loading: AssignedLoading,
    error: AssignedError,
  } = assignedOrdersState

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(getAllOrders())
      dispatch(assignedOrders())
    } else {
      history.push('/')
    }
  }, [dispatch, userInfo, history])
  return (
    <>
      <h1>Orders</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Tabs defaultActiveKey='data' id='uncontrolled-tab-example'>
          <Tab eventKey='data' title='Overview'>
              <OrderStats />
            </Tab>
            <Tab eventKey='processing' title='Processing'>
              {allOrdersData.processing ? (
                <OrdersTable data={allOrdersData.processing} />
              ) : (
                <p></p>
              )}
            </Tab>
            <Tab eventKey='pending' title='Pending Payment'>
              {allOrdersData.pending ? (
                <OrdersTable data={allOrdersData.pending} />
              ) : (
                <p></p>
              )}
            </Tab>
            <Tab eventKey='completed' title='Completed'>
              {allOrdersData.completed ? (
                <OrdersTable data={allOrdersData.completed} />
              ) : (
                <p></p>
              )}
            </Tab>
            <Tab eventKey='assigned' title='Assigned to you'>
              {myAssignedOrders ? (
                <OrdersTable data={myAssignedOrders} />
              ) : (
                <p></p>
              )}
            </Tab>
          </Tabs>
        </>
      )}
    </>
  )
}

export default OrderListScreen
