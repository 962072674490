import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Row, Col, Accordion, Card, Image } from 'react-bootstrap'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertToHTML, convertFromHTML } from 'draft-convert'
import { updatePrivacyPolicy } from '../../../redux/actions/settingsActions'

const EditPrivacyPolicyScreen = () => {
  const dispatch = useDispatch()
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const settings = useSelector((state) => state.settings)
  const { settings: siteSettings } = settings

  useEffect(() => {
    if (siteSettings[0]?.privacyPolicy) {
      setEditorState(() =>
        EditorState.createWithContent(
          convertFromHTML(siteSettings[0]?.privacyPolicy)
        )
      )
    }
  }, [siteSettings])

  const updatePrivacyPolicyAction = () => {
    let html = convertToHTML(editorState.getCurrentContent())
    dispatch(updatePrivacyPolicy(html))
  }

  return (
    <>
      <Link to='/admin/settings/home' className='btn btn-light my-3'>
        Go Back
      </Link>
      <h1>Edit Privacy Policy</h1>
      <Editor
        toolbarClassName='toolbarClassName'
        wrapperClassName='wrapperClassName'
        editorClassName='editorClassName'
        editorState={editorState}
        onEditorStateChange={setEditorState}
      />
      <Row className='justify-content-center py-3'>
        <Button
          variant='success'
          type='submit'
          onClick={(e) => updatePrivacyPolicyAction(e)}
        >
          Update
        </Button>
      </Row>
    </>
  )
}

export default EditPrivacyPolicyScreen
