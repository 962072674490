import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const DeliveryInformationScreen = () => {
  const settings = useSelector((state) => state.settings)
  const { settings: siteSettings } = settings

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <>
      <Helmet>
        <title>Delivery Information | JK Clothing</title>
      </Helmet>
      <Row>
        <Col>
          <Row>
            <h1>DELIVERY INFORMATION</h1>
          </Row>
          <Row>
            <div
              dangerouslySetInnerHTML={{
                __html: siteSettings[0]?.deliveryInformation,
              }}
            ></div>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default DeliveryInformationScreen
