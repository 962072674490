import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  productListReducer,
  productDetialsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productRelatedListReducer,
  productLatestListReducer,
} from './reducers/productReducers'
import { cartReducer, serverCartReducer } from './reducers/cartReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userAdminListReducer,
} from './reducers/userReducers'
import {
  categoriesReducer,
  categoriesListReducer,
  categoriesDeleteReducer,
  categoriesParentsListReducer,
  categoriesCreateReducer,
  categoriesDetailsReducer,
  categoriesUpdateReducer,
  categoriesProductReducer,
} from './reducers/categoriesReducers'
import {
  orderCreteReducer,
  orderdetailsReducer,
  orderpayReducer,
  orderListMyReducer,
  allorderListReducer,
  orderDelieverReducer,
  myOrderListMyReducer,
  orderDataReducer,
  salesDataReducer,
  topDataReducer,
} from './reducers/orderReducers'
import {
  variantListReducer,
  variantCreateReducer,
  variantDetialsReducer,
  variantUpdateReducer,
  variantDeleteReducer,
} from './reducers/variantReducers'
import { GetMediaLibraryReducer } from './reducers/mediaLibraryReducers'
import { settingsReducer } from './reducers/settingsReducers'

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetialsReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  categories: categoriesReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  orderCreate: orderCreteReducer,
  orderDetails: orderdetailsReducer,
  orderPay: orderpayReducer,
  orderDeliver: orderDelieverReducer,
  orderMyList: orderListMyReducer,
  usersList: userListReducer,
  userDelete: userDeleteReducer,
  userEdit: userUpdateReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  categoriesList: categoriesListReducer,
  categoriesDelete: categoriesDeleteReducer,
  categoriesCreate: categoriesCreateReducer,
  categoriesParents: categoriesParentsListReducer,
  allOrders: allorderListReducer,
  variantList: variantListReducer,
  variantCreate: variantCreateReducer,
  variantDetials: variantDetialsReducer,
  variantUpdate: variantUpdateReducer,
  variantDelete: variantDeleteReducer,
  categoriesDetails: categoriesDetailsReducer,
  categoriesUpdate: categoriesUpdateReducer,
  productUpdate: productUpdateReducer,
  categoriesProduct: categoriesProductReducer,
  productRelatedList: productRelatedListReducer,
  adminUsers: userAdminListReducer,
  myOrderList: myOrderListMyReducer,
  mediaLibrary: GetMediaLibraryReducer,
  settings: settingsReducer,
  serverCart: serverCartReducer,
  latestProducts: productLatestListReducer,
  orderData: orderDataReducer,
  salesData: salesDataReducer,
  topItemsData: topDataReducer,
})

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

const cartIDFromStorage = localStorage.getItem('cartID')
  ? JSON.parse(localStorage.getItem('cartID'))
  : null


const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const ShippingAddressFromStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress'))
  : {}

const ShippingTypeFromStorage = localStorage.getItem('shippingType')
  ? JSON.parse(localStorage.getItem('shippingType'))
  : {}

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: ShippingAddressFromStorage,
    shippingType: ShippingTypeFromStorage,
  },
  serverCart:{
   cartID: cartIDFromStorage,
   loading: false,
   serverCartItems: [],
  },
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
