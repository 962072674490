import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_TYPE,
  CART_INCREASE_ITEM,
  CREATE_USER_CART_REQUEST,
  CREATE_USER_CART_SUCCESS,
  CREATE_USER_CART_FAIL,
  CART_ADD_ITEM_REQUEST,
  CART_ADD_ITEM_SUCCESS,
  CART_ADD_ITEM_FAIL,
  CART_REMOVE_ITEM_REQUEST,
  CART_REMOVE_ITEM_SUCCESS,
  CART_REMOVE_ITEM_FAIL,
  GET_USER_CART_REQUEST,
  GET_USER_CART_SUCCESS,
  GET_USER_CART_FAIL,
  CART_INCREASE_ITEM_REQUEST,
  CART_INCREASE_ITEM_SUCCESS,
  CART_INCREASE_ITEM_FAIL,
  CART_RESET_REQUEST,
} from '../types'

export const cartReducer = (
  state = { cartItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload
      return {
        ...state,
        ...state.cartItems,
      }
    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      }
    case CART_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      }
    case CREATE_USER_CART_REQUEST:
      return {
        ...state,
      }
    default:
      return state
  }
}

export const serverCartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case CREATE_USER_CART_REQUEST:
      return { loading: true }
    case CREATE_USER_CART_SUCCESS:
      return { loading: false, cartID: action.payload }
    case CREATE_USER_CART_FAIL:
      return { loading: false, error: action.payload }
    case CART_ADD_ITEM_REQUEST:
      return { loading: true }
    case CART_ADD_ITEM_SUCCESS:
      return { loading: false, ...action.payload }
    case CART_ADD_ITEM_FAIL:
      return { loading: false, error: action.payload }
    case CART_REMOVE_ITEM_REQUEST:
      return { loading: true }
    case CART_REMOVE_ITEM_SUCCESS:
      return { loading: false, ...action.payload }
    case CART_REMOVE_ITEM_FAIL:
      return { loading: false, error: action.payload }
    case GET_USER_CART_REQUEST:
      return { loading: true }
    case GET_USER_CART_SUCCESS:
      return { loading: false, ...action.payload }
    case GET_USER_CART_FAIL:
      return { loading: false, error: action.payload }
    case CART_INCREASE_ITEM_REQUEST:
      return { loading: true }
    case CART_INCREASE_ITEM_SUCCESS:
      return { loading: false, ...action.payload }
    case CART_INCREASE_ITEM_FAIL:
      return { loading: false, error: action.payload }
    case CART_SAVE_SHIPPING_TYPE:
      return { loading: false, ...action.payload }
    case CART_RESET_REQUEST: 
    return {loading : false, cartItems: []}
    default:
      return state
  }
}
