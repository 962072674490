import axios from 'axios'
import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_REQUEST,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_REQUEST,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_MY_FAIL,
  ALL_ORDERS_DETAILS_FAIL,
  ALL_ORDERS_DETAILS_REQUEST,
  ALL_ORDERS_DETAILS_SUCCESS,
  ORDER_DELIEVER_REQUEST,
  ORDER_DELIEVER_SUCCESS,
  ORDER_DELIEVER_FAIL,
  MYORDER_LIST_MY_REQUEST,
  MYORDER_LIST_MY_SUCCESS,
  MYORDER_LIST_MY_FAIL,
  CART_RESET_REQUEST,
  ORDER_DATA_REQUEST,
  ORDER_DATA_SUCCESS,
  ORDER_DATA_FAIL,
  SALES_DATA_REQUEST,
  SALES_DATA_SUCCESS,
  SALES_DATA_FAIL,
  TOP_ITEMS_DATA_REQUEST,
  TOP_ITEMS_DATA_SUCCESS,
  TOP_ITEMS_DATA_FAIL,
} from '../types'

export const createOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/orders`, order, config)

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/orders/${id}`, config)

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const payOrder =
  (orderID, paymentResult) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_PAY_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `/api/orders/${orderID}/pay`,
        paymentResult,
        config
      )

      dispatch({
        type: CART_RESET_REQUEST,
      })

      dispatch({
        type: ORDER_PAY_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ORDER_PAY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateAssignee = (orderID, user) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/orders/${orderID}/assign/${user}`,
      '',
      config
    )
  } catch (error) {}
}

export const deliverOrder = (orderID) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELIEVER_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/orders/${orderID}/sent`, {}, config)

    dispatch({
      type: ORDER_DELIEVER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_DELIEVER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listMyOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_MY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/orders/myorders`, config)

    dispatch({
      type: ORDER_LIST_MY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_LIST_MY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAllOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ALL_ORDERS_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/orders/`, config)

    dispatch({
      type: ALL_ORDERS_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ALL_ORDERS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const assignedOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: MYORDER_LIST_MY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/orders/assignedorders`, config)

    dispatch({
      type: MYORDER_LIST_MY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MYORDER_LIST_MY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const sendCollectionEmail =
  (orderID, store) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `/api/orders/collection/${orderID}`,
        { store },
        config
      )
    } catch (error) {}
  }

export const sendReviewEmail =
  (orderID, store) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `/api/orders/review/${orderID}`,
        { store },
        config
      )
    } catch (error) {}
  }


  export const getOrderDataWeek = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_DATA_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.get(`/api/orders/data/week`, config)
  
      dispatch({
        type: ORDER_DATA_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ORDER_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  export const getOrderDataMonth = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_DATA_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.get(`/api/orders/data/month`, config)
  
      dispatch({
        type: ORDER_DATA_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ORDER_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  export const getSalesDataWeek = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: SALES_DATA_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.get(`/api/orders/data/amount/week`, config)
  
      dispatch({
        type: SALES_DATA_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SALES_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  export const getSalesDataAllTime = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: SALES_DATA_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.get(`/api/orders/data/amount/alltime`, config)
  
      dispatch({
        type: SALES_DATA_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SALES_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  export const getSalesDataMonth = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: SALES_DATA_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.get(`/api/orders/data/amount/month`, config)
  
      dispatch({
        type: SALES_DATA_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SALES_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  export const geOrderDataAllTime = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_DATA_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.get(`/api/orders/data/alltime`, config)
  
      dispatch({
        type: ORDER_DATA_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ORDER_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  export const getTopItemDataWeek = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: TOP_ITEMS_DATA_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.get(`/api/orders/data/popularitems/week`, config)
  
      dispatch({
        type: TOP_ITEMS_DATA_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: TOP_ITEMS_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  export const getTopItemDataMonth = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: TOP_ITEMS_DATA_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.get(`/api/orders/data/popularitems/month`, config)
  
      dispatch({
        type: TOP_ITEMS_DATA_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: TOP_ITEMS_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }


  export const getTopItemDataAllTime = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: TOP_ITEMS_DATA_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.get(`/api/orders/data/popularitems/alltime`, config)
  
      dispatch({
        type: TOP_ITEMS_DATA_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: TOP_ITEMS_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }