import React, { useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Pagination } from 'react-bootstrap'
import { useDispatch  } from 'react-redux'
import { deleteCategory } from '../../redux/actions/categoriesActions'

const CategoriesTable = ({data}) => {
    const dispatch = useDispatch()

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
  
    const totalPages = Math.ceil(data.length / itemsPerPage);
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    const paginatedData = data.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    const deleteHandler = (cId) => {
        if (window.confirm('Are you sure')) {
          dispatch(deleteCategory(cId))
        }
      }

  return (
    <>
    <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>NAME</th>
              <th>TYPE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((category) => (
              <tr key={category._id}>
                <td>{category.name}</td>
                <td>{category.displayType}</td>
                <td>
                  <LinkContainer to={`/admin/categories/${category._id}/edit`}>
                    <Button variant='light' className='btn-sm'>
                      <i className='fas fa-edit'></i>
                    </Button>
                  </LinkContainer>
                  <Button
                    variant='danger'
                    className='btn-sm'
                    onClick={() => {
                      deleteHandler(category._id)
                    }}
                  >
                    <i className='fas fa-trash'></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} />
        <Pagination.Prev
          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
        />
        {[...Array(totalPages).keys()].map((number) => (
          <Pagination.Item
            key={number + 1}
            active={number + 1 === currentPage}
            onClick={() => handlePageChange(number + 1)}
          >
            {number + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
        />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} />
      </Pagination>
        </>
  )
}

export default CategoriesTable