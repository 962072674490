import React, { useEffect } from 'react'
import { Col, Row, Form } from 'react-bootstrap'
import StatsBox from './StatsBox'
import OrdersBarChart from './OrdersBarChart'
import TopItemsSoldChart from './TopItemsSoldChart'
import { useDispatch, useSelector } from 'react-redux'
import { geOrderDataAllTime, getOrderDataMonth, getOrderDataWeek, getSalesDataAllTime, getSalesDataMonth, getSalesDataWeek, getTopItemDataAllTime, getTopItemDataMonth, getTopItemDataWeek } from '../redux/actions/orderActions'


const OrderStats = () => {
    const dispatch = useDispatch()

    const orderData = useSelector((state) => state.orderData)
    const { data, loading, error } = orderData

    console.log(data)

    useEffect(() => {
        dispatch(getOrderDataWeek())
        dispatch(getSalesDataWeek())
        dispatch(getTopItemDataWeek())
      }, [dispatch])

      const updateData = (value) => {
        if(value === 'week'){
            dispatch(getOrderDataWeek())
            dispatch(getSalesDataWeek())
            dispatch(getTopItemDataWeek())
        }else if(value === 'month'){
            dispatch(getOrderDataMonth())
            dispatch(getSalesDataMonth())
            dispatch(getTopItemDataMonth())
        }else if(value === 'alltime'){
            dispatch(geOrderDataAllTime())
            dispatch(getTopItemDataAllTime())
            dispatch(getSalesDataAllTime())
        }
      }

  return (
    <>
    <Row>
        <Col className='d-flex flex-row-reverse'>
        <div className='p-2' ><Form.Control
            as='select'
            onChange={(e) => updateData(e.target.value)}
          >
            <option value='week'>Week</option>
            <option value='month'>Month</option>
            <option value='alltime'>All Time</option>
          </Form.Control></div>
        </Col>
    </Row>
    <Row>
        <Col><StatsBox title={'Total Sales'} value={data?.totalValue} money={true} loading={loading} /></Col>
        <Col><StatsBox title={'Average Order'} value={data?.average} money={true} loading={loading} /></Col>
        <Col><StatsBox title={'Total Orders'} value={data?.amount} money={false} loading={loading}/></Col>
    </Row>
    <Row>
        <Col>
        <Row><div className='h2' style={{margin: '0px auto'}}>Sales</div></Row>
        <Row><OrdersBarChart /></Row>
        </Col>
        <Col>
        <Row><div className='h2' style={{margin: '0px auto'}}>Top Products Sold</div></Row>
        <Row><TopItemsSoldChart /></Row>
        </Col>
    </Row>
    </>
  )
}

export default OrderStats