import React, { useState } from 'react'
import { Button, Modal, Row, Col, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import SelectMediaImage from '../SelectMediaImage'
import { getMedia } from '../../redux/actions/mediaLibraryActions'
import Loader from '../../components/Loader'

const SelectMedia = ({ selectFunction, currentIndex }) => {
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [currentlySelected, setCurrentSelected] = useState(null)
  const [hasImage, setHasImage] = useState(true)
  const [selectedImage, setSelectedImage] = useState('')

  const mediaLibrary = useSelector((state) => state.mediaLibrary)
  let { loading, error, images: mediaLibraryImages } = mediaLibrary

  const handleClose = () => {
    setCurrentSelected(null)
    setShow(false)
    setHasImage(false)
  }

  const handleShow = () => {
    dispatch(getMedia())
    setShow(true)
  }

  const select = () => {
    selectFunction(selectedImage, currentIndex)
    handleClose()
  }

  const updateCurrentlySelected = (id, selectedImage) => {
    setCurrentSelected(id)
    setSelectedImage(selectedImage)
    setHasImage(false)
  }

  const ShowImages = () => {
    const images = []
    mediaLibraryImages &&
      mediaLibraryImages.map((image, index) =>
        images.push(
          <Col sm={12} md={6} lg={4} xl={3}>
            <SelectMediaImage
              image={image.image}
              count={index}
              updateSelected={updateCurrentlySelected}
              currentlySelected={currentlySelected}
            />
          </Col>
        )
      )
    return <> {loading ? <Loader /> : <Row>{images}</Row>}</>
  }

  return (
    <>
      <Button className='btn-sm py-2 my-4' onClick={handleShow}>
        Use image from media library
      </Button>

      <Modal size='xl' show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Media</Modal.Title>
        </Modal.Header>
        <Modal.Body Style='height: 700px; overflow-y: scroll;'>
          {ShowImages()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='success' onClick={select} disabled={hasImage}>
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SelectMedia
