import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../../components/FormContainer'
import { Form, Button, Row, Col, Accordion, Card, Image } from 'react-bootstrap'
import SelectMedia from '../../../components/modals/SelectMedia'
import Loader from '../../../components/Loader'
import axios from 'axios'

const CarouselImages = () => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const settings = useSelector((state) => state.settings)
  const { loading, settings: siteSettings } = settings

  const [image, setImage] = useState([])

  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    siteSettings[0] && setImage(siteSettings[0].homePageCarousel)
  }, [siteSettings])

  const submitHandler = (e) => {
    e.preventDefault()
  }

  const uploadImages = () => {
    return (
      <Accordion>
        {image.map((attr, i) => (
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={attr + i}>
              Image {i + 1}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={attr + i}>
              <Form.Group key={attr + i}>
                <Row className='px-3  py-3'>
                  <Col>
                    <Image src={attr} fluid />
                  </Col>
                  <Col>
                    <SelectMedia
                      selectFunction={SelectImageFromMediaLibrary}
                      currentIndex={i}
                    />
                    <Form.Group controlId='name'>
                      <Form.Control
                        type='name'
                        placeholder='Enter Image'
                        defaultValue={attr}
                        readOnly
                      ></Form.Control>
                      <Form.File
                        id='image-file'
                        label='Choose File'
                        custom
                        onChange={(e) => uploadFileImages(e, i, attr)}
                      ></Form.File>
                      {uploading && <Loader />}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col>
                    <Button
                      variant='primary'
                      className='float-right mx-3'
                      onClick={(e) => {
                        handleDeleteChangeImage(i, attr)
                      }}
                      style={{ marginTop: '25px' }}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    )
  }

  const handleAddImageClick = () => {
    setImage([
      ...image,
      'https://jkclothing-images.s3.eu-west-2.amazonaws.com/products/default.jpg',
    ])
  }

  const handleDeleteChangeImage = (index, name) => {
    const list = [...image]
    list.splice(index, 1)
    setImage(list)
  }

  const uploadFileImages = async (e, index) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)

      if (data) {
        const list = [...image]
        list[index] = data
        setImage(list)
      }
      setUploading(false)
    } catch (error) {
      setUploading(false)
    }
  }

  const SelectImageFromMediaLibrary = (newImage, index) => {
    const list = [...image]
    list[index] = newImage
    setImage(list)
  }

  return (
    <>
      <Link to='/admin/settings/home' className='btn btn-light my-3'>
        Go Back
      </Link>
      {loading ? (
        <Loader />
      ) : (
        <FormContainer>
          <h1>Home page Carousel Images</h1>
          <Form onSubmit={submitHandler}>
            <Form.Group>
              <Row className='py-2 justify-content-md-center'>
                <Col xs={12} md={4}>
                  <Button
                    variant='primary'
                    onClick={() => {
                      handleAddImageClick()
                    }}
                    style={{ marginTop: '25px' }}
                  >
                    Add New Image
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>{uploadImages()}</Col>
              </Row>
            </Form.Group>
          </Form>
        </FormContainer>
      )}
    </>
  )
}

export default CarouselImages
