import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_RESET,
  ORDER_LIST_MY_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_RESET,
  ORDER_PAY_SUCCESS,
  ALL_ORDERS_DETAILS_FAIL,
  ALL_ORDERS_DETAILS_REQUEST,
  ALL_ORDERS_DETAILS_SUCCESS,
  ORDER_DELIEVER_FAIL,
  ORDER_DELIEVER_REQUEST,
  ORDER_DELIEVER_SUCCESS,
  ORDER_DELIEVER_RESET,
  MYORDER_LIST_MY_REQUEST,
  MYORDER_LIST_MY_SUCCESS,
  MYORDER_LIST_MY_FAIL,
  MYORDER_LIST_MY_RESET,
  ORDER_DATA_REQUEST,
  ORDER_DATA_SUCCESS,
  ORDER_DATA_FAIL,
  SALES_DATA_REQUEST,
  SALES_DATA_SUCCESS,
  SALES_DATA_FAIL,
  TOP_ITEMS_DATA_REQUEST,
  TOP_ITEMS_DATA_SUCCESS,
  TOP_ITEMS_DATA_FAIL,
} from '../types'

export const orderCreteReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return {
        success: false,
        loading: true,
      }
    case ORDER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      }
    case ORDER_CREATE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const orderdetailsReducer = (
  state = { loading: true, orderItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      }
    case ORDER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const orderpayReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PAY_REQUEST:
      return {
        loading: true,
      }
    case ORDER_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case ORDER_PAY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_PAY_RESET:
      return {}
    default:
      return state
  }
}

export const orderDelieverReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELIEVER_REQUEST:
      return {
        loading: true,
      }
    case ORDER_DELIEVER_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case ORDER_DELIEVER_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_DELIEVER_RESET:
      return {}
    default:
      return state
  }
}

export const orderListMyReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_MY_REQUEST:
      return {
        loading: true,
      }
    case ORDER_LIST_MY_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      }
    case ORDER_LIST_MY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_LIST_MY_RESET:
      return {
        orders: [],
      }
    default:
      return state
  }
}

export const myOrderListMyReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case MYORDER_LIST_MY_REQUEST:
      return {
        loading: true,
      }
    case MYORDER_LIST_MY_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      }
    case MYORDER_LIST_MY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case MYORDER_LIST_MY_RESET:
      return {
        orders: [],
      }
    default:
      return state
  }
}

export const allorderListReducer = (state = { allOrders: [] }, action) => {
  switch (action.type) {
    case ALL_ORDERS_DETAILS_REQUEST:
      return {
        loading: true,
      }
    case ALL_ORDERS_DETAILS_SUCCESS:
      return {
        loading: false,
        allOrders: action.payload,
      }
    case ALL_ORDERS_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const orderDataReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case ORDER_DATA_REQUEST:
      return {
        loading: true,
      }
    case ORDER_DATA_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      }
    case ORDER_DATA_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const salesDataReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case SALES_DATA_REQUEST:
      return {
        loading: true,
      }
    case SALES_DATA_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      }
    case SALES_DATA_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const topDataReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case TOP_ITEMS_DATA_REQUEST:
      return {
        loading: true,
      }
    case TOP_ITEMS_DATA_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      }
    case TOP_ITEMS_DATA_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
