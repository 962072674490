import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Loader from './Loader'

const StatsBox = ({title, value, money, loading}) => {

    let valueText = ''

    if(money === true){
        valueText = '£' + value
    }else{
        valueText = value
    }
   
if(loading){
    return (
        <div className='m-3 p-3 text-center' style={{border: 'black 2px solid', borderRadius: '20px'}}>
           <Loader />
        </div>
      )
}else{
return (
    <div className='m-3 p-3 text-center' style={{border: 'black 2px solid', borderRadius: '20px'}}>
        <Row>
            <Col>{title}</Col>
        </Row>
        <Row className='py-3 font-weight-bold' style={{fontSize: '25px'}}>
            <Col>{valueText}</Col>
        </Row>
    </div>
  )
}
}

export default StatsBox