import React, { useState } from 'react'
import { Image } from 'react-bootstrap'

const ImageSlider = ({ images }) => {
  const [current, setCurrent] = useState(0)
  const length = images.length

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  const showSliderElements = () => {
    if (images.length > 1) {
      return (
        <>
          <i
            class='fas fa-chevron-left fa-4x image-selector-left'
            onClick={prevSlide}
          ></i>
          <i
            class='fas fa-chevron-right fa-4x image-selector-right'
            onClick={nextSlide}
          ></i>
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <>
      {showSliderElements()}
      {images.map((image, index) => {
        return (
          <div key={index}>
            {index === current && <Image src={image} fluid />}
          </div>
        )
      })}
    </>
  )
}

export default ImageSlider
