import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import Rating from './Rating'

const Product = ({ product, category }) => {
  const priceArray = []
  let showFromPrice = false

  if (product.childProducts.length > 0) {
    showFromPrice = true
    priceArray.push(product.price)
    product.childProducts.map((item) => priceArray.push(item.price))
    var min_of_array = Math.min.apply(Math, priceArray)
  }

  return (
    <Card className='my-3 p-3 rounded'>
      <Link
        to={{
          pathname: `/product/${product._id}`,
          state: { cSlug: category },
        }}
      >
        <Card.Img src={product.image[0]} variant='top' />
      </Link>
      <Card.Body>
        <Link
          to={{
            pathname: `/product/${product._id}`,
            state: { cSlug: category },
          }}
        >
          <Card.Title as='div' className='text-center' Style='font-weight: 900'>
            <strong>{product.name}</strong>
          </Card.Title>
        </Link>
        <Card.Text as='div'>
          <div className='my-3'>
            <Rating
              value={product.rating}
              text={`${product.numReviews} reviews`}
            />
          </div>
        </Card.Text>
        {showFromPrice ? (
          <Card.Text className='text-center' as='h4'>
            From: £{min_of_array.toFixed(2)}
          </Card.Text>
        ) : (
          <Card.Text className='text-center' as='h4'>
            £{product.price.toFixed(2)}
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  )
}

export default Product
