import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {useSelector } from 'react-redux'
import { load } from 'dotenv';
import Loader from './Loader';

const OrdersBarChart = () => {

    const salesData = useSelector((state) => state.salesData)
    const { data, loading, error } = salesData

    if(loading){
        return (
            <Loader />
        )
    }else{
  return (
    <ResponsiveContainer width="100%" height={500}>
    <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="date" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Bar dataKey="orders" fill="#8884d8" />
  </BarChart>
  </ResponsiveContainer>
  )}
}

export default OrdersBarChart