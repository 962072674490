import React, { useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Pagination } from 'react-bootstrap'
import { useDispatch  } from 'react-redux'
import { deleteUser } from '../../redux/actions/userActions'

const UsersTable = ({data}) => {
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
  
    const totalPages = Math.ceil(data.length / itemsPerPage);
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    const paginatedData = data.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    const deleteHandler = (userId) => {
        if (window.confirm('Are you sure')) {
          dispatch(deleteUser(userId))
        }
      }

    
      const renderPaginationItems = () => {
        const items = [];
        const maxPagesToShow = 10;
        let startPage, endPage;
    
        if (totalPages <= maxPagesToShow) {
          // show all pages
          startPage = 1;
          endPage = totalPages;
        } else {
          // calculate start and end pages
          const middlePage = Math.floor(maxPagesToShow / 2);
          if (currentPage <= middlePage) {
            startPage = 1;
            endPage = maxPagesToShow;
          } else if (currentPage + middlePage >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
          } else {
            startPage = currentPage - middlePage;
            endPage = currentPage + middlePage;
          }
        }
    
        // Add the first page and ellipsis if needed
        if (startPage > 1) {
          items.push(
            <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
              1
            </Pagination.Item>
          );
          if (startPage > 2) {
            items.push(<Pagination.Ellipsis key="start-ellipsis" />);
          }
        }
    
        // Add page numbers
        for (let number = startPage; number <= endPage; number++) {
          items.push(
            <Pagination.Item
              key={number}
              active={number === currentPage}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </Pagination.Item>
          );
        }
    
        // Add the last page and ellipsis if needed
        if (endPage < totalPages) {
          if (endPage < totalPages - 1) {
            items.push(<Pagination.Ellipsis key="end-ellipsis" />);
          }
          items.push(
            <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
              {totalPages}
            </Pagination.Item>
          );
        }
    
        return items;
      };


  return (
   <>
    <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>ADMIN</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((user) => (
              <tr key={user._id}>
                <td>{user.name}</td>
                <td>
                  <a href={`mailto:${user.email}`}>{user.email}</a>
                </td>
                <td>
                  {user.isAdmin ? (
                    <i className='fas fa-check' style={{ color: 'green' }}></i>
                  ) : (
                    <i className='fas fa-times' style={{ color: 'red' }}></i>
                  )}
                </td>
                <td>
                  <LinkContainer to={`/admin/user/${user._id}/edit`}>
                    <Button variant='light' className='btn-sm'>
                      <i className='fas fa-edit'></i>
                    </Button>
                  </LinkContainer>
                  <Button
                    variant='danger'
                    className='btn-sm'
                    onClick={() => {
                      deleteHandler(user._id)
                    }}
                  >
                    <i className='fas fa-trash'></i>
                  </Button>
                </td>
                <td>
                  <LinkContainer to={`/admin/user/edit`}>
                    <Button variant='light' className='btn-sm'>
                      view orders
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} />
        <Pagination.Prev
          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
        />
         {renderPaginationItems()}
        <Pagination.Next
          onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
        />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} />
      </Pagination>
        </>
  )
}

export default UsersTable