import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Categories from '../components/categories'
import {
  getBaseCategories,
  getSlugCategories,
} from '../redux/actions/categoriesActions'
import { Helmet } from 'react-helmet'

const CategoryScreen = ({ match }) => {
  const dispatch = useDispatch()

  const categoryList = useSelector((state) => state.categories)
  const { categories, loading, error } = categoryList

  const [titleText, setTitleText] = useState('Categories')

  const selectedCategory = match.params.slug ? match.params.slug : 'none'

  useEffect(() => {
    if (selectedCategory === 'none') {
      dispatch(getBaseCategories())
    } else {
      //todo make a function to do this nicer
      const fullD = categories.find((item) => {
        return item.slug === selectedCategory
      })

      let cName = fullD ? fullD.name : selectedCategory

      cName = cName.replace('-', ' ')

      setTitleText(cName)
      dispatch(getSlugCategories(selectedCategory))
    }
  }, [dispatch, selectedCategory])

  return (
    <>
      <h1>{titleText}</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : categories.length === 0 ? (
        <Message>This category currently has no subcategories</Message>
      ) : (
        <>
          <Helmet>
            <title>{titleText}</title>
          </Helmet>
          <Row>
            {categories.map((category) => (
              <Col
                className='align-items-stretch d-flex'
                key={category._id}
                sm={12}
                md={6}
                lg={4}
                xl={3}
              >
                <Categories category={category} />
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  )
}

export default CategoryScreen
