import axios from 'axios'
import {
  CATEGORIES_GET_BASE_REQUEST,
  CATEGORIES_GET_BASE_SUCCESS,
  CATEGORIES_GET_BASE_FAIL,
  CATEGORIES_GET_SLUG_REQUEST,
  CATEGORIES_GET_SLUG_SUCCESS,
  CATEGORIES_GET_SLUG_FAIL,
  CATEGORIES_GET_ALL_REQUEST,
  CATEGORIES_GET_ALL_SUCCESS,
  CATEGORIES_GET_ALL_FAIL,
  CATEGORIES_DELETE_REQUEST,
  CATEGORIES_DELETE_SUCCESS,
  CATEGORIES_DELETE_FAIL,
  CATEGORIES_GET_PARENTS_REQUEST,
  CATEGORIES_GET_PARENTS_SUCCESS,
  CATEGORIES_GET_PARENTS_FAIL,
  CATEGORIES_CREATE_REQUEST,
  CATEGORIES_CREATE_SUCCESS,
  CATEGORIES_CREATE_FAIL,
  CATEGORIES_DETAILS_REQUEST,
  CATEGORIES_DETAILS_SUCCESS,
  CATEGORIES_DETAILS_FAIL,
  CATEGORIES_UPDATE_REQUEST,
  CATEGORIES_UPDATE_SUCCESS,
  CATEGORIES_UPDATE_FAIL,
  CATEGORIES_GET_PRODUCT_FAIL,
  CATEGORIES_GET_PRODUCT_REQUEST,
  CATEGORIES_GET_PRODUCT_SUCCESS,
} from '../types'

export const getBaseCategories = () => async (dispatch) => {
  try {
    dispatch({ type: CATEGORIES_GET_BASE_REQUEST })
    const { data } = await axios.get('/api/categories')
    dispatch({
      type: CATEGORIES_GET_BASE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CATEGORIES_GET_BASE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProductCategories = () => async (dispatch) => {
  try {
    dispatch({ type: CATEGORIES_GET_PRODUCT_REQUEST })
    const { data } = await axios.get('/api/categories/products')
    dispatch({
      type: CATEGORIES_GET_PRODUCT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CATEGORIES_GET_PRODUCT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getSlugCategories = (slug) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORIES_GET_SLUG_REQUEST })
    const { data } = await axios.get(`/api/categories/${slug}`)
    dispatch({
      type: CATEGORIES_GET_SLUG_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CATEGORIES_GET_SLUG_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getallCategories = () => async (dispatch) => {
  try {
    dispatch({ type: CATEGORIES_GET_ALL_REQUEST })
    const { data } = await axios.get('/api/categories/all')
    dispatch({
      type: CATEGORIES_GET_ALL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CATEGORIES_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getParentCategories = () => async (dispatch) => {
  try {
    dispatch({ type: CATEGORIES_GET_PARENTS_REQUEST })
    const { data } = await axios.get('/api/categories/parents')
    dispatch({
      type: CATEGORIES_GET_PARENTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CATEGORIES_GET_PARENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteCategory = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIES_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/categories/${id}`, config)

    dispatch({
      type: CATEGORIES_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: CATEGORIES_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createCategory = (category) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIES_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/categories`, category, config)

    dispatch({
      type: CATEGORIES_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CATEGORIES_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCategoryDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIES_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/categories/get/${id}`, config)

    dispatch({
      type: CATEGORIES_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CATEGORIES_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateCategory = (category) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIES_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/categories/${category.id}`,
      category,
      config
    )

    dispatch({
      type: CATEGORIES_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: CATEGORIES_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CATEGORIES_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
