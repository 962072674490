export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PROCUCT_LIST_FAIL = 'PROCUCT_LIST_FAIL'
export const PROCUCT_LIST_RESET = 'PROCUCT_LIST_RESET'

export const PRODUCT_LATEST_REQUEST = 'PRODUCT_LATEST_REQUEST'
export const PRODUCT_LATEST_SUCCESS = 'PRODUCT_LATEST_SUCCESS'
export const PRODUCT_LATEST_FAIL = 'PRODUCT_LATEST_FAIL'

export const PRODUCT_LIST_SLUG_REQUEST = 'PRODUCT_LIST_SLUG_REQUEST'
export const PRODUCT_LIST_SLUG_SUCCESS = 'PRODUCT_LIST_SLUG_SUCCESS'
export const PROCUCT_LIST_SLUG_FAIL = 'PROCUCT_LIST_SLUG_FAIL'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PROCUCT_DETAILS_FAIL = 'PROCUCT_DETAILS_FAIL'

export const CART_ADD_ITEM = 'CART_ADD_ITEM'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'
export const CART_INCREASE_ITEM = 'CART_INCREASE_ITEM'
export const CART_SAVE_SHIPPING_ADDRESS = 'CART_SAVE_SHIPPING_ADDRESS'
export const CART_SAVE_PAYMENT_METHOD = 'CART_SAVE_PAYMENT_METHOD'
export const CART_SAVE_SHIPPING_TYPE = 'CART_SAVE_SHIPPING_TYPE'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const CATEGORIES_GET_BASE_SUCCESS = 'CATEGORIES_GET_BASE_SUCCESS'
export const CATEGORIES_GET_BASE_REQUEST = 'CATEGORIES_GET_BASE_REQUEST'
export const CATEGORIES_GET_BASE_FAIL = 'CATEGORIES_GET_BASE_FAIL'
export const CATEGORIES_GET_SLUG_SUCCESS = 'CATEGORIES_GET_SLUG_SUCCESS'
export const CATEGORIES_GET_SLUG_REQUEST = 'CATEGORIES_GET_SLUG_REQUEST'
export const CATEGORIES_GET_SLUG_FAIL = 'CATEGORIES_GET_SLUG_FAIL'

export const CATEGORIES_GET_ALL_SUCCESS = 'CATEGORIES_GET_ALL_SUCCESS'
export const CATEGORIES_GET_ALL_REQUEST = 'CATEGORIES_GET_ALL_REQUEST'
export const CATEGORIES_GET_ALL_FAIL = 'CATEGORIES_GET_ALL_FAIL'

export const CATEGORIES_GET_PARENTS_SUCCESS = 'CATEGORIES_GET_PARENTS_SUCCESS'
export const CATEGORIES_GET_PARENTS_REQUEST = 'CATEGORIES_GET_PARENTS_REQUEST'
export const CATEGORIES_GET_PARENTS_FAIL = 'CATEGORIES_GET_PARENTS_FAIL'

export const CATEGORIES_DELETE_REQUEST = 'CATEGORIES_DELETE_REQUEST'
export const CATEGORIES_DELETE_SUCCESS = 'CATEGORIES_DELETE_SUCCESS'
export const CATEGORIES_DELETE_FAIL = 'CATEGORIES_DELETE_FAIL'

export const CATEGORIES_CREATE_REQUEST = 'CATEGORIES_CREATE_REQUEST'
export const CATEGORIES_CREATE_SUCCESS = 'CATEGORIES_CREATE_SUCCESS'
export const CATEGORIES_CREATE_FAIL = 'CATEGORIES_CREATE_FAIL'
export const CATEGORIES_CREATE_RESET = 'CATEGORIES_CREATE_RESET'

export const CATEGORIES_DETAILS_REQUEST = 'CATEGORIES_DETAILS_REQUEST'
export const CATEGORIES_DETAILS_SUCCESS = 'CATEGORIES_DETAILS_SUCCESS'
export const CATEGORIES_DETAILS_FAIL = 'CATEGORIES_DETAILS_FAIL'

export const CATEGORIES_UPDATE_REQUEST = 'CATEGORIES_UPDATE_REQUEST'
export const CATEGORIES_UPDATE_SUCCESS = 'CATEGORIES_UPDATE_SUCCESS'
export const CATEGORIES_UPDATE_FAIL = 'CATEGORIES_UPDATE_FAIL'
export const CATEGORIES_UPDATE_RESET = 'CATEGORIES_UPDATE_RESET'

export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'
export const ORDER_DETAILS_RESET = 'ORDER_DETAILS_RESET'

export const ORDER_DELIEVER_REQUEST = 'ORDER_DELIEVER_REQUEST'
export const ORDER_DELIEVER_SUCCESS = 'ORDER_DELIEVER_SUCCESS'
export const ORDER_DELIEVER_FAIL = 'ORDER_DELIEVER_FAIL'
export const ORDER_DELIEVER_RESET = 'ORDER_DELIEVER_RESET'

export const ALL_ORDERS_DETAILS_REQUEST = 'ALL_ORDERS_DETAILS_REQUEST'
export const ALL_ORDERS_DETAILS_SUCCESS = 'ALL_ORDERS_DETAILS_SUCCESS'
export const ALL_ORDERS_DETAILS_FAIL = 'ALL_ORDERS_DETAILS_FAIL'

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST'
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS'
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL'
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET'

export const ORDER_LIST_MY_REQUEST = 'ORDER_LIST_MY_REQUEST'
export const ORDER_LIST_MY_SUCCESS = 'ORDER_LIST_MY_SUCCESS'
export const ORDER_LIST_MY_FAIL = 'ORDER_LIST_MY_FAIL'
export const ORDER_LIST_MY_RESET = 'ORDER_LIST_MY_RESET'

export const MYORDER_LIST_MY_REQUEST = 'MYORDER_LIST_MY_REQUEST'
export const MYORDER_LIST_MY_SUCCESS = 'MYORDER_LIST_MY_SUCCESS'
export const MYORDER_LIST_MY_FAIL = 'MYORDER_LIST_MY_FAIL'
export const MYORDER_LIST_MY_RESET = 'MYORDER_LIST_MY_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const ADMINUSER_LIST_REQUEST = 'ADMINUSER_LIST_REQUEST'
export const ADMINUSER_LIST_SUCCESS = 'ADMINUSER_LIST_SUCCESS'
export const ADMINUSER_LIST_FAIL = 'ADMINUSER_LIST_FAIL'
export const ADMINUSER_LIST_RESET = 'ADMINUSER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL'

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'

export const PRODUCT_RELATED_REQUEST = 'PRODUCT_RELATED_REQUEST'
export const PRODUCT_RELATED_SUCCESS = 'PRODUCT_RELATED_SUCCESS'
export const PRODUCT_RELATED_FAIL = 'PRODUCT_RELATED_FAIL'

export const VARIANT_LIST_REQUEST = 'VARIANT_LIST_REQUEST'
export const VARIANT_LIST_SUCCESS = 'VARIANT_LIST_SUCCESS'
export const VARIANT_LIST_FAIL = 'VARIANT_LIST_FAIL'
export const VARIANT_LIST_RESET = 'VARIANT_LIST_RESET'

export const VARIANT_CREATE_REQUEST = 'VARIANT_CREATE_REQUEST'
export const VARIANT_CREATE_SUCCESS = 'VARIANT_CREATE_SUCCESS'
export const VARIANT_CREATE_FAIL = 'VARIANT_CREATE_FAIL'
export const VARIANT_CREATE_RESET = 'VARIANT_CREATE_RESET'

export const VARIANT_DETAILS_REQUEST = 'VARIANT_DETAILS_REQUEST'
export const VARIANT_DETAILS_SUCCESS = 'VARIANT_DETAILS_SUCCESS'
export const VARIANT_DETAILS_FAIL = 'VARIANT_DETAILS_FAIL'

export const VARIANT_UPDATE_REQUEST = 'VARIANT_UPDATE_REQUEST'
export const VARIANT_UPDATE_SUCCESS = 'VARIANT_UPDATE_SUCCESS'
export const VARIANT_UPDATE_FAIL = 'VARIANT_UPDATE_FAIL'
export const VARIANT_UPDATE_RESET = 'VARIANT_UPDATE_RESET'

export const VARIANT_DELETE_REQUEST = 'VARIANT_DELETE_REQUEST'
export const VARIANT_DELETE_SUCCESS = 'VARIANT_DELETE_SUCCESS'
export const VARIANT_DELETE_FAIL = 'VARIANT_DELETE_FAIL'

export const CATEGORIES_GET_PRODUCT_REQUEST = 'CATEGORIES_GET_PRODUCT_REQUEST'
export const CATEGORIES_GET_PRODUCT_SUCCESS = 'CATEGORIES_GET_PRODUCT_SUCCESS'
export const CATEGORIES_GET_PRODUCT_FAIL = 'CATEGORIES_GET_PRODUCT_FAIL'

export const GET_MEDIA_LIBRARY_REQUEST = 'GET_MEDIA_LIBRARY_REQUEST'
export const GET_MEDIA_LIBRARY_SUCCESS = 'GET_MEDIA_LIBRARY_SUCCESS'
export const GET_MEDIA_LIBRARY_FAIL = 'GET_MEDIA_LIBRARY_FAIL'

export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST'
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const GET_SETTINGS_FAIL = 'GET_SETTINGS_FAIL'

export const UPDATE_RETURNS_POLICY_REQUEST = 'UPDATE_RETURNS_POLICY_REQUEST'
export const UPDATE_RETURNS_POLICY_SUCCESS = 'UPDATE_RETURNS_POLICY_SUCCESS'
export const UPDATE_RETURNS_POLICY_FAIL = 'UPDATE_RETURNS_POLICY_FAIL'

export const UPDATE_DELIVERY_INFORMATION_REQUEST =
  'UPDATE_DELIVERY_INFORMATION_REQUEST'
export const UPDATE_DELIVERY_INFORMATION_SUCCESS =
  'UPDATE_DELIVERY_INFORMATION_SUCCESS'
export const UPDATE_DELIVERY_INFORMATION_FAIL =
  'UPDATE_DELIVERY_INFORMATION_FAIL'

export const UPDATE_TERMS_CONDITIONS_REQUEST = 'UPDATE_TERMS_CONDITIONS_REQUEST'
export const UPDATE_TERMS_CONDITIONS_SUCCESS = 'UPDATE_TERMS_CONDITIONS_SUCCESS'
export const UPDATE_TERMS_CONDITIONS_FAIL = 'UPDATE_TERMS_CONDITIONS_FAIL'

export const CREATE_USER_CART_REQUEST = 'CREATE_USER_CART_REQUEST'
export const CREATE_USER_CART_SUCCESS = 'CREATE_USER_CART_SUCCESS'
export const CREATE_USER_CART_FAIL = 'CREATE_USER_CART_FAIL'

export const CART_ADD_ITEM_REQUEST = 'CART_ADD_ITEM_REQUEST'
export const CART_ADD_ITEM_SUCCESS = 'CART_ADD_ITEM_SUCCESS'
export const CART_ADD_ITEM_FAIL = 'CART_ADD_ITEM_FAIL'

export const CART_REMOVE_ITEM_REQUEST = 'CART_REMOVE_ITEM_REQUEST'
export const CART_REMOVE_ITEM_SUCCESS = 'CART_REMOVE_ITEM_SUCCESS'
export const CART_REMOVE_ITEM_FAIL = 'CART_REMOVE_ITEM_FAIL'

export const GET_USER_CART_REQUEST = 'GET_USER_CART_REQUEST'
export const GET_USER_CART_SUCCESS = 'GET_USER_CART_SUCCESS'
export const GET_USER_CART_FAIL = 'GET_USER_CART_FAIL'

export const CART_INCREASE_ITEM_REQUEST = 'CART_INCREASE_ITEM_REQUEST'
export const CART_INCREASE_ITEM_SUCCESS = 'CART_INCREASE_ITEM_SUCCESS'
export const CART_INCREASE_ITEM_FAIL = 'CART_INCREASE_ITEM_FAIL'

export const CART_RESET_REQUEST = 'CART_RESET_REQUEST'
export const CART_RESET_SUCCESS = 'CART_RESET_SUCCESS'
export const CART_RESET_FAIL = 'CART_RESET_FAIL'

export const SET_SHOP_REQUEST = 'SET_SHOP_REQUEST'
export const SET_SHOP_SUCCESS = 'SET_SHOP_SUCCESS'
export const SET_SHOP_FAIL = 'SET_SHOP_FAIL'


export const ORDER_DATA_REQUEST = 'ORDER_DATA_REQUEST'
export const ORDER_DATA_SUCCESS = 'ORDER_DATA_SUCCESS'
export const ORDER_DATA_FAIL = 'ORDER_DATA_FAIL'

export const SALES_DATA_REQUEST = 'SALES_DATA_REQUEST'
export const SALES_DATA_SUCCESS = 'SALES_DATA_SUCCESS'
export const SALES_DATA_FAIL = 'SALES_DATA_FAIL'

export const TOP_ITEMS_DATA_REQUEST = 'TOP_ITEMS_DATA_REQUEST'
export const TOP_ITEMS_DATA_SUCCESS = 'TOP_ITEMS_DATA_SUCCESS'
export const TOP_ITEMS_DATA_FAIL = 'TOP_ITEMS_DATA_FAIL'