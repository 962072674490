import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Navbar, Nav, NavDropdown, Badge } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../redux/actions/userActions'
import { Helmet } from 'react-helmet'
import { getSettings } from '../redux/actions/settingsActions'
import { getServerSideBasket } from '../redux/actions/cartAction'

const Header = () => {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const serverCart = useSelector((state) => state.serverCart)
  const { orderItems: serverCartItems } = serverCart

  const settings = useSelector((state) => state.settings)
  const { settings: siteSettings } = settings

  useEffect(() => {
    if(serverCartItems === undefined)
    {
    dispatch(getServerSideBasket())
    }
    dispatch(getSettings())
  }, [dispatch])

  const logoutHandler = () => {
    dispatch(logout())
  }
  return (
    <header>
      <Helmet>
        <title>{siteSettings[0] && siteSettings[0].siteName}</title>
        <link
          rel='icon'
          type='image/png'
          href={siteSettings[0] && siteSettings[0].favicon}
          sizes='16x16'
        />
      </Helmet>
      <Navbar collapseOnSelect expand='lg'>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>
              {siteSettings[0] && siteSettings[0].siteName}
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ml-auto'>
              <LinkContainer to='/category'>
                <Nav.Link>Store</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/cart'>
                <Nav.Link>
                  <i className='fas fa-shopping-cart'></i> Cart
                  <Badge
                    style={{ marginLeft: '10px', borderRadius: '3px' }}
                    variant='danger'
                  >
                    {serverCartItems?.length}
                  </Badge>
                </Nav.Link>
              </LinkContainer>
              {userInfo ? (
                <NavDropdown title={userInfo.name} id='username'>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Log Out
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link>
                    <i className='fas fa-user'></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
              <LinkContainer to='/contact'>
                <Nav.Link>Contact Us</Nav.Link>
              </LinkContainer>
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title='Admin'>
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/products'>
                    <NavDropdown.Item>Products</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/categories'>
                    <NavDropdown.Item>Categories</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/orders'>
                    <NavDropdown.Item>Orders</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/variants'>
                    <NavDropdown.Item>Variants</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/settings/home'>
                    <NavDropdown.Item>Settings</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
