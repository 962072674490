import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import {
  Form,
  Button,
  Tabs,
  Tab,
  Row,
  Col,
  Accordion,
  Card,
  Image,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {
  ListProductDetails,
  updateProduct,
} from '../../redux/actions/productActions'
import { variantsProducts } from '../../redux/actions/variantsActions'
import { getProductCategories } from '../../redux/actions/categoriesActions'
import { PRODUCT_UPDATE_RESET } from '../../redux/types'
import SelectMedia from '../../components/modals/SelectMedia'

const ProductEditScreen = ({ history, match }) => {
  const productId = match.params.id

  const [name, setName] = useState('')
  const [price, setPrice] = useState(0)
  const [image, setImage] = useState([])
  const [countInStock, setCountInStock] = useState(0)
  const [description, setDescription] = useState('')

  const [seletedVariants, setSeletedVariants] = useState('')
  const [variantsList, setVariantsList] = useState()

  const [productCategories, setProductCategories] = useState([])

  const [productOptionNameLabel, setProductOptionNameLabel] = useState(false)
  const [productOptionTMSProduct, setProductOptionTMSProduct] = useState(false)
  const [productOptionCSFGProduct, setProductOptionCSFGProduct] =
    useState(false)
  const [productOptionRGTIProduct, setProductOptionRGTIProduct] =
    useState(false)

  const [productVariants, setProductVariants] = useState([])
  const [selectedVariantsPicked, setSelectedVariantsPicked] = useState('')

  const [publishState, setPublishState] = useState(false)

  const [inputList, setInputList] = useState([])

  const [uploading, setUploading] = useState(false)

  const [newProduct, setNewProduct] = useState(true)

  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const variantList = useSelector((state) => state.variantList)
  const { variants } = variantList

  const categoriesProduct = useSelector((state) => state.categoriesProduct)
  const { categories } = categoriesProduct

  const productUpdate = useSelector((state) => state.productUpdate)
  const { success } = productUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [categoryOrder, setCategoryOrder] = useState(0)

  useEffect(() => {
    if (success) {
      dispatch({ type: PRODUCT_UPDATE_RESET })
      history.push('/admin/products?reload=true')
    } else {
      if (!product.name || product._id !== productId) {
        dispatch(ListProductDetails(productId))
        dispatch(variantsProducts())
        dispatch(getProductCategories())
      } else {
        setName(product.name)
        setPrice(product.price)
        setImage(product.image)
        setCountInStock(product.countInStock)
        setDescription(product.description)
        setInputList(product.attributes)
        setProductVariants(product.childProducts)
        setProductCategories(product.category)
        setPublishState(product.publishState)
        setProductOptionNameLabel(product.nameLabelProduct)
        setProductOptionTMSProduct(product.tmsProduct)
        setProductOptionCSFGProduct(product.csfgProduct)
        setProductOptionRGTIProduct(product.rgtiProduct)
        setNewProduct(product.newProduct)
        setCategoryOrder(product.caregoryOrder)

        inputList.map((i) => {
          if (i.useForVar === true) {
            const item = variants && variants.find((x) => x.name === i.name)
            setVariantsList(item)
          }
        })
      }
    }
  }, [dispatch, history, productId, product, variants, success])

  const submitHandler = (e) => {
    dispatch(
      updateProduct({
        id: productId,
        name: name,
        price: price,
        description: description,
        image: image,
        countInStock: countInStock,
        attributes: inputList,
        childProducts: productVariants,
        category: productCategories,
        nameLabelProduct: productOptionNameLabel,
        tmsProduct: productOptionTMSProduct,
        csfgProduct: productOptionCSFGProduct,
        rgtiProduct: productOptionRGTIProduct,
        newProduct: false,
        publishState: publishState,
        caregoryOrder: categoryOrder,
      })
    )
  }

  const updateVList = () => {
    inputList.map((i) => {
      if (i.useForVar === true) {
        const item = variants && variants.find((x) => x.name === i.name)
        setVariantsList(item)
      }
    })
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleCheckChange = (e, index) => {
    const { name, checked } = e.target
    const list = [...inputList]
    list[index][name] = checked
    setInputList(list)
    updateVList()
  }

  const handleAddAttrClick = () => {
    setInputList([...inputList, { name: seletedVariants, useForVar: false }])
  }

  const handleAddVarClick = () => {
    setProductVariants([
      ...productVariants,
      {
        name: selectedVariantsPicked,
        price: 0,
        variationimage: '',
        countInStock: 0,
      },
    ])
    const nList = variantsList.varintItems.filter(function (i) {
      return i.name !== selectedVariantsPicked
    })
    variantsList.varintItems = nList
  }

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleInputChangeVariation = (e, index) => {
    const { name, value } = e.target
    const list = [...productVariants]
    list[index][name] = value
    setProductVariants(list)
  }

  const handleDeleteChangeVariation = (index, name) => {
    const list = [...productVariants]
    list.splice(index, 1)
    setProductVariants(list)
    variantsList.varintItems && variantsList.varintItems.push({ name })
  }

  const handleDeleteChangeImage = (index, name) => {
    const list = [...image]
    list.splice(index, 1)
    setImage(list)
  }

  const deleteLastImage = async (name) => {
    const fileName = name.substring(name.lastIndexOf('/') + 1)
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      await axios.delete(`/api/upload/${fileName}`, config)
    } catch (error) {}
  }

  const handleProductPublish = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(`/api/products/publish/${id}`, config)
      if (data._id) {
        history.push('/admin/products?reload=true')
      }
    } catch (error) {}
  }

  const uploadFileVariationsHandler = async (e, index, name) => {
    deleteLastImage(name)
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)

      if (data) {
        const list = [...productVariants]
        list[index]['variationimage'] = data
        setProductVariants(list)
      }
      setUploading(false)
    } catch (error) {
      setUploading(false)
    }
  }

  function variantProductExists(variant) {
    return productVariants.some(function (el) {
      return el.name === variant
    })
  }

  function hasCategory(Category) {
    return productCategories.some(function (el) {
      return el.name === Category
    })
  }

  function handleCategoryCheckboxChange(event) {
    var option = event.target.getAttribute('data-slug')

    if (event.target.checked) {
      if (!productCategories.includes(option)) {
        setProductCategories([...productCategories, { name: option }])
      }
    } else {
      const newList = productCategories.filter((c) => c.name !== option)
      setProductCategories(newList)
    }
  }

  const showPublishButton = () => {
    if (publishState === false && newProduct === false) {
      return (
        <Button
          onClick={() => {
            handleProductPublish(product._id)
          }}
          className='float-right'
          variant='primary'
        >
          Publish
        </Button>
      )
    }
  }

  const showSavePublishButton = () => {
    if (publishState === false) {
      return (
        <Button
          onClick={() => {
            saveAndPublish()
          }}
          className='float-right'
          variant='primary'
        >
          Save and Publish
        </Button>
      )
    }
  }

  const saveAndPublish = () => {
    dispatch(
      updateProduct({
        id: productId,
        name: name,
        price: price,
        description: description,
        image: image,
        countInStock: countInStock,
        attributes: inputList,
        childProducts: productVariants,
        category: productCategories,
        nameLabelProduct: productOptionNameLabel,
        tmsProduct: productOptionTMSProduct,
        csfgProduct: productOptionCSFGProduct,
        rgtiProduct: productOptionRGTIProduct,
        newProduct: false,
        publishState: true,
        caregoryOrder: categoryOrder,
      })
    )
  }

  const handleAddImageClick = () => {
    setImage([
      ...image,
      'https://jkclothing-images.s3.eu-west-2.amazonaws.com/products/default.jpg',
    ])
  }

  const uploadFileImages = async (e, index) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)

      if (data) {
        const list = [...image]
        list[index] = data
        setImage(list)
      }
      setUploading(false)
    } catch (error) {
      setUploading(false)
    }
  }

  const SelectImageFromMediaLibrary = (newImage, index) => {
    const list = [...image]
    list[index] = newImage
    setImage(list)
  }

  const SelectImageFromMediaLibraryVariant = (newImage, index) => {
    const list = [...productVariants]
    list[index]['variationimage'] = newImage
    setProductVariants(list)
  }

  const uploadImages = () => {
    return (
      <Accordion>
        {image.map((attr, i) => (
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={attr + i}>
              Image {i + 1}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={attr + i}>
              <Form.Group key={attr + i}>
                <Row className='px-3  py-3'>
                  <Col>
                    <Image src={attr} fluid />
                  </Col>
                  <Col>
                    <SelectMedia
                      selectFunction={SelectImageFromMediaLibrary}
                      currentIndex={i}
                    />
                    <Form.Group controlId='name'>
                      <Form.Control
                        type='name'
                        placeholder='Enter Image'
                        defaultValue={attr}
                        readOnly
                      ></Form.Control>
                      <Form.File
                        id='image-file'
                        label='Choose File'
                        custom
                        onChange={(e) => uploadFileImages(e, i, attr)}
                      ></Form.File>
                      {uploading && <Loader />}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col>
                    <Button
                      variant='primary'
                      className='float-right mx-3'
                      onClick={(e) => {
                        handleDeleteChangeImage(i, attr)
                      }}
                      style={{ marginTop: '25px' }}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    )
  }

  return (
    <>
      <Link to='/admin/products' className='btn btn-light my-3'>
        Go Back
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Row>
            <Col>
              <h1>Edit Product</h1>
            </Col>
            <Col className='my-4'>
              Publish State:
              {publishState === true ? (
                <span className='mx-2'>Published</span>
              ) : (
                <span className='mx-2'>Draft</span>
              )}
            </Col>
            <Col>
              <Button
                onClick={() => {
                  submitHandler()
                }}
                variant='primary'
              >
                Save
              </Button>
              {showSavePublishButton()}
              {showPublishButton()}
            </Col>
          </Row>
          <Form onSubmit={submitHandler} noValidate>
            <Tabs defaultActiveKey='basic' id='uncontrolled-tab-example'>
              <Tab eventKey='basic' title='Basic Information'>
                <Row className='justify-content-md-center py-3'>
                  <Col xs={12} md={7}>
                    <Form.Group controlId='name'>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type='name'
                        placeholder='Enter name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='price'>
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        type='number'
                        placeholder='Enter price'
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='countInStock'>
                      <Form.Label>Count In Stock</Form.Label>
                      <Form.Control
                        type='number'
                        placeholder='Enter count In Stock'
                        value={countInStock}
                        onChange={(e) => setCountInStock(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='Description'>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter description'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Row className='py-2 justify-content-md-center'>
                      <Col xs={12} md={4}>
                        <Button
                          variant='primary'
                          onClick={() => {
                            handleAddImageClick()
                          }}
                          style={{ marginTop: '25px' }}
                        >
                          Add New Image
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>{uploadImages()}</Col>
                    </Row>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey='category' title='Categories'>
                <Row className='justify-content-md-center py-3'>
                  <Col xs={12} md={6}>
                    <Form.Group id='formGridCheckbox'>
                      {categories.map((category) => (
                        <Form.Check
                          type='checkbox'
                          data-slug={category.slug}
                          label={category.parentPath}
                          checked={hasCategory(category.slug)}
                          onChange={(e) => handleCategoryCheckboxChange(e)}
                        />
                      ))}
                    </Form.Group>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey='attributes' title='Attributes'>
                <Row className='justify-content-md-center py-3'>
                  <Col xs={12} md={9}>
                    <Row>
                      <Col>
                        <Form.Group controlId='parent'>
                          <Form.Label>Attributes list</Form.Label>
                          <Form.Control
                            as='select'
                            onChange={(e) => setSeletedVariants(e.target.value)}
                          >
                            <option value='none'>None</option>
                            {variants &&
                              variants.map((option) => (
                                <option key={option.name} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Button
                          variant='primary'
                          onClick={() => {
                            handleAddAttrClick()
                          }}
                          style={{ marginTop: '25px' }}
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                    {inputList.map((attr, i) => (
                      <Form.Group key={attr + i}>
                        <Row>
                          <Col>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type='name'
                              name='name'
                              placeholder='Enter name'
                              readOnly
                              value={attr.name}
                              onChange={(e) => handleInputChange(e, i)}
                            ></Form.Control>
                          </Col>
                          <Col>
                            <Form.Group controlId='isadmin'>
                              <Form.Check
                                type='checkbox'
                                name='useForVar'
                                label='Use for Variations'
                                onChange={(e) => handleCheckChange(e, i)}
                                style={{ marginTop: '40px' }}
                                checked={attr.useForVar}
                              ></Form.Check>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Button
                              variant='primary'
                              onClick={() => {
                                handleRemoveClick(i)
                              }}
                              style={{ marginTop: '25px' }}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      </Form.Group>
                    ))}
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey='variations' title='Variations'>
                <Row className='justify-content-md-center py-3'>
                  <Col xs={12} md={9}>
                    <Row>
                      <Col>
                        <Form.Group controlId='123'>
                          <Form.Label>Attributes list</Form.Label>
                          <Form.Control
                            as='select'
                            onChange={(e) =>
                              setSelectedVariantsPicked(e.target.value)
                            }
                          >
                            <option value='none'>None</option>
                            {variantsList &&
                              variantsList.varintItems.map((option) =>
                                !variantProductExists(option.name) ? (
                                  <option key={option.name} value={option.name}>
                                    {option.name}
                                  </option>
                                ) : (
                                  <></>
                                )
                              )}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Button
                          variant='primary'
                          onClick={() => {
                            handleAddVarClick()
                          }}
                          style={{ marginTop: '25px' }}
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <Accordion>
                      {productVariants.map((attr, i) => (
                        <Card>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={attr + i}
                          >
                            {attr.name}
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={attr + i}>
                            <Form.Group key={attr + i}>
                              <Row className='px-3 py-3'>
                                <Col>
                                  <Form.Label>Price</Form.Label>
                                  <Form.Control
                                    type='number'
                                    name='price'
                                    placeholder='Enter price'
                                    defaultValue={attr.price}
                                    onChange={(e) =>
                                      handleInputChangeVariation(e, i)
                                    }
                                  ></Form.Control>
                                </Col>
                                <Col></Col>
                              </Row>
                              <Row className='px-3 py-3'>
                                <Col>
                                  <Form.Label>Count In Stock</Form.Label>
                                  <Form.Control
                                    type='number'
                                    name='countInStock'
                                    placeholder='Enter count In Stock'
                                    defaultValue={attr.countInStock}
                                    onChange={(e) =>
                                      handleInputChangeVariation(e, i)
                                    }
                                  ></Form.Control>
                                </Col>
                                <Col></Col>
                              </Row>
                              <Row className='px-3  py-3'>
                                <Col>
                                  <Image src={attr.variationimage} fluid />
                                </Col>
                                <Col>
                                  <SelectMedia
                                    selectFunction={
                                      SelectImageFromMediaLibraryVariant
                                    }
                                    currentIndex={i}
                                  />
                                  <Form.Group controlId='name'>
                                    <Form.Control
                                      type='name'
                                      placeholder='Enter Image'
                                      defaultValue={attr.variationimage}
                                      readOnly
                                    ></Form.Control>
                                    <Form.File
                                      id='image-file'
                                      label='Choose File'
                                      custom
                                      onChange={(e) =>
                                        uploadFileVariationsHandler(
                                          e,
                                          i,
                                          attr.variationimage
                                        )
                                      }
                                    ></Form.File>
                                    {uploading && <Loader />}
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col></Col>
                                <Col>
                                  <Button
                                    variant='primary'
                                    className='float-right mx-3'
                                    onClick={(e) => {
                                      handleDeleteChangeVariation(i, attr.name)
                                    }}
                                    style={{ marginTop: '25px' }}
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Accordion.Collapse>
                        </Card>
                      ))}
                    </Accordion>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey='options' title='Product Options'>
                <Row className='justify-content-md-center py-3'>
                  <Col xs={12} md={6}>
                    <Form.Group id='productOptionNameLabel'>
                      <Form.Check
                        type='checkbox'
                        label='Show Name Label Option'
                        checked={productOptionNameLabel}
                        onChange={(e) =>
                          setProductOptionNameLabel(e.target.checked)
                        }
                      ></Form.Check>
                      <Form.Check
                        type='checkbox'
                        label='Show Thomas More Initials Option'
                        checked={productOptionTMSProduct}
                        onChange={(e) =>
                          setProductOptionTMSProduct(e.target.checked)
                        }
                      ></Form.Check>
                      <Form.Check
                        type='checkbox'
                        label='Show Chislehurst School For Girls Embroidered Name Option'
                        checked={productOptionCSFGProduct}
                        onChange={(e) =>
                          setProductOptionCSFGProduct(e.target.checked)
                        }
                      ></Form.Check>
                      <Form.Check
                        type='checkbox'
                        label='Show Royal Greenwich Trust Initials Option'
                        checked={productOptionRGTIProduct}
                        onChange={(e) =>
                          setProductOptionRGTIProduct(e.target.checked)
                        }
                      ></Form.Check>
                      <Form.Group controlId='price'>
                        <Form.Label>Category order</Form.Label>
                        <Form.Control
                          type='number'
                          placeholder='Enter number'
                          value={categoryOrder}
                          onChange={(e) => setCategoryOrder(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Form.Group>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Form>
        </>
      )}
    </>
  )
}

export default ProductEditScreen
