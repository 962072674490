import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import {useSelector } from 'react-redux'
import Loader from './Loader';

const TopItemsSoldChart = () => {

    const topItemsData = useSelector((state) => state.topItemsData)
    const { data, loading, error } = topItemsData

    if(loading){
        return (
            <Loader />
        )
    }else{
  return (
    <ResponsiveContainer width="100%" height={500}>
    <BarChart
      data={data}
      layout="vertical"
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" />
      <YAxis dataKey="product" type="category" />
      <Tooltip />
      <Legend />
      <Bar dataKey="count" name="amount" fill="#8884d8">
      </Bar>
    </BarChart>
  </ResponsiveContainer>
  )
}}

export default TopItemsSoldChart