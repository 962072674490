import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../components/FormContainer'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {
  getParentCategories,
  createCategory,
} from '../../redux/actions/categoriesActions'
import { CATEGORIES_CREATE_RESET } from '../../redux/types'

const CategoryCreateScreen = ({ history }) => {
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [displayType, setDisplayType] = useState('')
  const [parent, setParent] = useState('none')
  const [image, setImage] = useState(
    'https://jkclothing-images.s3.eu-west-2.amazonaws.com/products/default.jpg'
  )
  const [uploading, setUploading] = useState(false)
  const [external, setExternal] = useState(false)
  const [externalLink, SetExternalLink] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const categoriesParent = useSelector((state) => state.categoriesParents)
  const { categoriesParentList: parents } = categoriesParent

  const categoriesCreate = useSelector((state) => state.categoriesCreate)
  const { success } = categoriesCreate

  useEffect(() => {
    if (success) {
      dispatch({
        type: CATEGORIES_CREATE_RESET,
      })
      history.push('/admin/categories')
    } else {
      if (userInfo && userInfo.isAdmin) {
        dispatch(getParentCategories())
      } else {
        history.push('/')
      }
    }
  }, [dispatch, userInfo, history, success])

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)
      setImage(data)
      setUploading(false)
    } catch (error) {
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createCategory({
        name,
        displayType,
        parentCategory: parent,
        image,
        external,
        externalLink,
      })
    )
  }

  return (
    <>
      <Link to='/admin/categories' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Create Category</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter name'
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='parent'>
            <Form.Label>Parent category</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => setParent(e.target.value)}
            >
              <option value='none'>None</option>
              {parents &&
                parents.map((option) => (
                  <option key={option.slug} value={option.slug}>
                    {option.name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId='type'>
            <Form.Label>Display type</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => setDisplayType(e.target.value)}
            >
              <option value='none'>Select...</option>
              <option value='products'>Products</option>
              <option value='subcategories'>Subcategories</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId='name'>
            <Form.Control
              type='name'
              placeholder='Enter Image'
              value={image}
              onChange={(e) => setImage(e.target.value)}
              readOnly
            ></Form.Control>
            <Form.File
              id='image-file'
              label='Choose File'
              custom
              onChange={uploadFileHandler}
            ></Form.File>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group>
            <Form.Check
              type='checkbox'
              label='Link to external site'
              checked={external}
              onChange={(e) => setExternal(e.target.checked)}
            ></Form.Check>
          </Form.Group>
          <Form.Group controlId='link'>
            <Form.Label>External Link</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter external link'
              onChange={(e) => SetExternalLink(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Button type='submit' variant='primary'>
            Create
          </Button>
        </Form>
      </FormContainer>
    </>
  )
}

export default CategoryCreateScreen
