import axios from 'axios'
import {
  VARIANT_LIST_SUCCESS,
  VARIANT_LIST_REQUEST,
  VARIANT_LIST_FAIL,
  VARIANT_CREATE_SUCCESS,
  VARIANT_CREATE_REQUEST,
  VARIANT_CREATE_FAIL,
  VARIANT_DETAILS_FAIL,
  VARIANT_DETAILS_REQUEST,
  VARIANT_DETAILS_SUCCESS,
  VARIANT_UPDATE_FAIL,
  VARIANT_UPDATE_REQUEST,
  VARIANT_UPDATE_SUCCESS,
  VARIANT_DELETE_REQUEST,
  VARIANT_DELETE_SUCCESS,
  VARIANT_DELETE_FAIL,
} from '../../redux/types'

export const variantsProducts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: VARIANT_LIST_REQUEST })

    const { data } = await axios.get(`/api/variant`)
    dispatch({
      type: VARIANT_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: VARIANT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createVariant = (variant) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VARIANT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/variant`, variant, config)

    dispatch({
      type: VARIANT_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: VARIANT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getVariantDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VARIANT_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/variant/${id}`, config)

    dispatch({
      type: VARIANT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: VARIANT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateVariant = (variant) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VARIANT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/variant/${variant.id}`,
      variant,
      config
    )

    dispatch({
      type: VARIANT_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: VARIANT_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: VARIANT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteVariant = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VARIANT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/variant/${id}`, config)

    dispatch({
      type: VARIANT_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: VARIANT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
