import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../components/FormContainer'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {
  getParentCategories,
  getCategoryDetails,
  updateCategory,
} from '../../redux/actions/categoriesActions'
import { CATEGORIES_UPDATE_RESET } from '../../redux/types'

const CategoryEditScreen = ({ history, match }) => {
  const categoryId = match.params.id
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [displayType, setDisplayType] = useState('')
  const [parent, setParent] = useState('none')
  const [image, setImage] = useState('')
  const [uploading, setUploading] = useState(false)
  const [external, setExternal] = useState(false)
  const [externalLink, SetExternalLink] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const categoriesParent = useSelector((state) => state.categoriesParents)
  const { categoriesParentList: parents } = categoriesParent

  const categoriesDetails = useSelector((state) => state.categoriesDetails)
  const { category, loading } = categoriesDetails

  const categoriesUpdate = useSelector((state) => state.categoriesUpdate)
  const { success } = categoriesUpdate

  useEffect(() => {
    if (success) {
      dispatch({ type: CATEGORIES_UPDATE_RESET })
      history.push('/admin/categories')
    } else {
      if (userInfo && userInfo.isAdmin) {
        if (!category.name || category._id !== categoryId) {
          dispatch(getCategoryDetails(categoryId))
          dispatch(getParentCategories())
        } else {
          setImage(category.image)
          setName(category.name)
          setParent(category.parentCategory)
          setDisplayType(category.displayType)
          setExternal(category.external)
          SetExternalLink(category.externalLink)
        }
      } else {
        history.push('/')
      }
    }
  }, [dispatch, userInfo, history, success, categoryId, category])

  const uploadFileHandler = async (e, name) => {
    deleteLastImage(name)
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch (error) {
      setUploading(false)
    }
  }

  const deleteLastImage = async (name) => {
    const fileName = name.substring(name.lastIndexOf('/') + 1)
    if (fileName !== 'default.jpg') {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
        await axios.delete(`/api/upload/${fileName}`, config)
      } catch (error) {}
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateCategory({
        id: categoryId,
        name: name,
        parentCategory: parent,
        displayType: displayType,
        image: image,
        external: external,
        externalLink: externalLink,
      })
    )
  }
  return (
    <>
      <Link to='/admin/categories' className='btn btn-light my-3'>
        Go Back
      </Link>
      {loading ? (
        <Loader />
      ) : (
        <FormContainer>
          <h1>Edit Category</h1>
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='parent'>
              <Form.Label>Parent category</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => setParent(e.target.value)}
                value={parent}
              >
                <option value='none'>None</option>
                {parents &&
                  parents.map((option) => (
                    <option key={option.slug} value={option.slug}>
                      {option.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='type'>
              <Form.Label>Display type</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => setDisplayType(e.target.value)}
                value={displayType}
              >
                <option value='products'>Products</option>
                <option value='subcategories'>Subcategories</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='name'>
              <Form.Control
                type='name'
                placeholder='Enter Image'
                value={image}
                onChange={(e) => setImage(e.target.value)}
                readOnly
              ></Form.Control>
              <Form.File
                id='image-file'
                label='Choose File'
                custom
                onChange={(e) => uploadFileHandler(e, image)}
              ></Form.File>
              {uploading && <Loader />}
            </Form.Group>
            <Form.Group>
              <Form.Check
                type='checkbox'
                label='Link to external site'
                checked={external}
                onChange={(e) => setExternal(e.target.checked)}
              ></Form.Check>
            </Form.Group>
            <Form.Group controlId='link'>
              <Form.Label>External Link</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter external link'
                value={externalLink}
                onChange={(e) => SetExternalLink(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        </FormContainer>
      )}
    </>
  )
}

export default CategoryEditScreen
