import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Card, Button, Form } from 'react-bootstrap'
import Rating from '../components/Rating'
import {
  ListProductDetails,
  relatedProducts,
} from '../redux/actions/productActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { variantsProducts } from '../redux/actions/variantsActions'
import Product from '../components/Product'
import ImageSlider from '../components/ImageSlider'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const ProductScreen = ({ history, match }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  })

  const relatedSlug = history?.location?.state?.cSlug

  let hasV = false

  const productId = match.params.id
  const [qty, setQty] = useState(1)
  const dispatch = useDispatch()

  const variantList = useSelector((state) => state.variantList)
  const { variants } = variantList

  const productRelatedList = useSelector((state) => state.productRelatedList)
  const { products } = productRelatedList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [variantsList, setVariantsList] = useState([])
  const [price, setPrice] = useState(0)
  const [countInStock, setCountInStock] = useState(0)
  const [image, setImage] = useState([])

  const [pickedVariant, setPickedVariant] = useState('')
  const [attrList, setAttrList] = useState([])

  const [displayError, setDisplayError] = useState('')

  const productDetails = useSelector((state) => state.productDetails)

  const { loading, error, product } = productDetails

  const [nameLabels, setNameLabels] = useState(false)
  const [nameLabelsName, setNameLabelsName] = useState('')
  const [nameLabelsAmount, setNameLabelsAmount] = useState(0)

  const [TMSOption, setTMSOption] = useState(false)
  const [TMSOptionValue, setTMSOptionValue] = useState('')

  const [CSFGOption, setCSFGOption] = useState(false)
  const [CSFGOptionValue, setCSFGOptionValue] = useState('')

  const [rftiOption, setrftiOption] = useState(false)
  const [rftiOptionValue, setrftiOptionValue] = useState('')

  //TODO: look at global settings to set this
  const useStockSystem = false

  useEffect(() => {
    if (!product.name || product._id !== productId) {
      dispatch(ListProductDetails(productId))
      dispatch(variantsProducts())
    } else {
      setPrice(product.price)
      setImage(product.image)
      setCountInStock(product.countInStock)
    }
    dispatch(relatedProducts(relatedSlug))
  }, [dispatch, match.params.id, product, productId, relatedSlug])

  useEffect(() => {
    if (product && variants) {
      const toadd = []
      product.attributes &&
        product.attributes.map((i) => {
          const item = variants && variants.find((x) => x.name === i.name)
          toadd.push({ ...item, i })
        })
      //console.log(toadd[0].varintItems)
      var test1 = toadd[0]?.varintItems?.sort((orderA, orderB) =>
        orderA.order > orderB.order ? 1 : -1
      )

      setVariantsList(toadd)
    }
  }, [product, variants])

  const addtoCart = () => {
    if (qty < 1) {
      setDisplayError('Please select an qty')
    } else {
      if (hasV) {
        if (attrList.length > 0 && variantsList.length === attrList.length) {
          var attrString = ''

          attrList.map((a) => {
            attrString = attrString.concat(
              '&' +
                a.type +
                '=' +
                a.value.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, ' ')
            )
          })

          if (pickedVariant) {
            attrString = attrString.concat('&v=' + pickedVariant)
          }

          if (nameLabels) {
            attrString = attrString.concat('&label-amount=' + nameLabelsAmount)
            attrString = attrString.concat('&label-name=' + nameLabelsName)
          }

          if (TMSOption) {
            attrString = attrString.concat('&thomas-initials=' + TMSOptionValue)
          }

          if (rftiOption) {
            attrString = attrString.concat(
              '&royal-greenwich-trust-initials=' + rftiOptionValue
            )
          }

          if (CSFGOption) {
            attrString = attrString.concat(
              '&chislehurst-embroidered-name=' + CSFGOptionValue
            )
          }
          history.push(
            `/cart/${match.params.id}?qty=${qty}${encodeURIComponent(
              attrString
            )}`
          )
        } else {
          setDisplayError('Please select an option')
        }
      } else {
        history.push(`/cart/${match.params.id}?qty=${qty}`)
        //setDisplayError('Please select an option')
      }
    }
  }

  const updateProductDetails = (e) => {
    setDisplayError()
    const { value } = e.target
    var index = e.target.selectedIndex
    var optionElement = e.target.childNodes[index]
    var option = optionElement.getAttribute('data-type')
    var isVar = optionElement.getAttribute('data-var')
    if (value !== 'none' && isVar === 'true') {
      const item =
        product.childProducts &&
        product.childProducts.find((x) => x.name === value)
      if (item) {
        if (item.price > price) {
          setPrice(item.price)
        }
        setPickedVariant(item._id)
        if (item.variationimage[0] != '') {
          setImage(item.variationimage)
        } else {
          setImage(product.image)
        }
        setCountInStock(item.countInStock)
        //check if the option is already in the array - if it is remove it.
        var filteredAry = attrList.filter((e) => e.type !== option)
        setAttrList([...filteredAry, { type: option, value, id: item._id }])
        setNameLabels(false)
        setNameLabelsAmount(0)
        setNameLabelsName('')
        setTMSOption(false)
        setTMSOptionValue('')
        setCSFGOption(false)
        setCSFGOptionValue('')
        setrftiOption(false)
        setrftiOptionValue('')
      } else {
        var filteredAry = attrList.filter((e) => e.type !== option)
        setAttrList([...filteredAry, { type: option, value }])
        setImage(product.image)
        setPrice(product.price)
        setCountInStock(product.countInStock)
        setNameLabels(false)
        setNameLabelsAmount(0)
        setNameLabelsName('')
        setTMSOption(false)
        setTMSOptionValue('')
        setCSFGOption(false)
        setCSFGOptionValue('')
        setrftiOption(false)
        setrftiOptionValue('')
      }
    } else {
      var filteredAry = attrList.filter((e) => e.type !== option)
      setAttrList([...filteredAry, { type: option, value }])
    }
  }

  const updateProductPrice = (e) => {
    const picked = e.target.value
    let basePrice = price
    if (picked !== 'none') {
      if (nameLabelsAmount !== 0) {
        if (nameLabelsAmount === 12) {
          basePrice = basePrice - 2.5
        } else if (nameLabelsAmount === 24) {
          basePrice = basePrice - 4
        }
      }

      const number = Number(picked)
      if (number === 12) {
        basePrice = basePrice + 2.5
        setPrice(basePrice)
        setNameLabelsAmount(12)
      } else if (number === 24) {
        basePrice = basePrice + 4
        setPrice(basePrice)
        setNameLabelsAmount(24)
      }
    } else if (picked === 'none') {
      if (nameLabelsAmount !== 0) {
        if (nameLabelsAmount === 12) {
          basePrice = basePrice - 2.5
          setPrice(basePrice)
          setNameLabelsAmount(0)
        } else if (nameLabelsAmount === 24) {
          basePrice = basePrice - 4
          setPrice(basePrice)
          setNameLabelsAmount(0)
        }
      }
    }
  }

  const initialsProductSeleted = (e) => {
    setTMSOption(e)
    let basePrice = price

    if (e === true) {
      basePrice = basePrice + 3.5
      setPrice(basePrice)
    } else {
      basePrice = basePrice - 3.5
      setPrice(basePrice)
    }
  }

  const CSNameProductSelected = (e) => {
    setCSFGOption(e)
    let basePrice = price
    if (e === true) {
      basePrice = basePrice + 3.5
      setPrice(basePrice)
    } else {
      basePrice = basePrice - 3.5
      setPrice(basePrice)
    }
  }

  const RGTIOptipn = (e) => {
    setrftiOption(e)
    let basePrice = price
    if (e === true) {
      basePrice = basePrice + 3
      setPrice(basePrice)
    } else {
      basePrice = basePrice - 3
      setPrice(basePrice)
    }
  }

  const showVariants = () => {
    if (variantsList.length > 0) {
      hasV = true
      //setHasVariant(true)
      return variantsList.map((i) => (
        <>
          <span className='text-center'>{i.name}</span>
          <ListGroup.Item>
            <Form.Control as='select' onChange={(e) => updateProductDetails(e)}>
              <option value='none'>None</option>
              {i.varintItems &&
                i.varintItems.map((option) => (
                  <option
                    key={option.name}
                    data-type={i.name}
                    value={option.name}
                    data-var={i.i.useForVar}
                  >
                    {option.name}
                  </option>
                ))}
            </Form.Control>
          </ListGroup.Item>
        </>
      ))
    } else {
      hasV = false
      return <p></p>
    }
  }

  const showNameLabels = (value) => {
    if (value === true) {
      setNameLabels(value)
      var nPrice = price + 2.5
      setPrice(nPrice)
      setNameLabelsAmount(12)
    } else {
      setNameLabels(false)
      if (nameLabelsAmount === 12) {
        var nPrice = price - 2.5
        setPrice(nPrice)
      } else {
        var nPrice = price - 4
        setPrice(nPrice)
      }
    }
  }

  return (
    <>
      {displayError && <Message variant='danger'>{displayError}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Helmet>
            <title>{product.name}</title>
          </Helmet>
          <Row>
            <Col md={4}>
              <ImageSlider images={image} />
            </Col>
            <Col md={5}>
              <Row>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <h3>{product.name}</h3>
                  </ListGroup.Item>
                  <Rating
                    value={product.rating}
                    text={`${product.numReviews} reviews`}
                  />
                  <ListGroup.Item>
                    description: {product.description}
                  </ListGroup.Item>
                  <ListGroup.Item>Price: £{price.toFixed(2)}</ListGroup.Item>
                </ListGroup>
              </Row>
              {product.nameLabelProduct && product.nameLabelProduct ? (
                <Row>
                  <Form.Group controlId='namelables'>
                    <Form.Check
                      type='checkbox'
                      name='namelables'
                      label='Add Iron-in name labels'
                      style={{ marginTop: '40px' }}
                      checked={nameLabels}
                      onChange={(e) => showNameLabels(e.target.checked)}
                    ></Form.Check>
                  </Form.Group>
                </Row>
              ) : (
                <></>
              )}
              {nameLabels === true ? (
                <>
                  <Row>
                    <Form.Group>
                      <Form.Label>Number of Labels </Form.Label>
                      <Form.Control
                        as='select'
                        onChange={(e) => updateProductPrice(e)}
                      >
                        <option value='12'>12 (£2.50)</option>
                        <option value='24'>24 (£4.00)</option>
                      </Form.Control>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group controlId='name'>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type='name'
                        placeholder='Enter name'
                        value={nameLabelsName}
                        onChange={(e) => setNameLabelsName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <strong>
                      Please check this is correct as items cannot be refunded
                      once customised
                    </strong>
                  </Row>
                </>
              ) : (
                <p></p>
              )}
              {product.tmsProduct && product.tmsProduct ? (
                <Row>
                  <Form.Group controlId='namelables'>
                    <Form.Check
                      type='checkbox'
                      name='namelables'
                      label='Add Thomas More Initials (£3.50)'
                      style={{ marginTop: '40px' }}
                      checked={TMSOption}
                      onChange={(e) => initialsProductSeleted(e.target.checked)}
                    ></Form.Check>
                  </Form.Group>
                  <strong>
                    Please check this is correct as items cannot be refunded
                    once customised
                  </strong>
                </Row>
              ) : (
                <></>
              )}
              {TMSOption === true ? (
                <>
                  <Row>
                    <Form.Group controlId='name'>
                      <Form.Label>Initials</Form.Label>
                      <Form.Control
                        type='name'
                        placeholder='Enter Initials'
                        value={TMSOptionValue}
                        onChange={(e) => setTMSOptionValue(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <strong>
                      Please check this is correct as items cannot be refunded
                      once customised
                    </strong>
                  </Row>
                </>
              ) : (
                <p></p>
              )}
              {product.csfgProduct && product.csfgProduct ? (
                <Row>
                  <Form.Group controlId='namelables'>
                    <Form.Check
                      type='checkbox'
                      name='namelables'
                      label='Add Embroidered Name (£3.50)'
                      style={{ marginTop: '40px' }}
                      checked={CSFGOption}
                      onChange={(e) => CSNameProductSelected(e.target.checked)}
                    ></Form.Check>
                  </Form.Group>
                  <strong>
                    Please check this is correct as items cannot be refunded
                    once customised
                  </strong>
                </Row>
              ) : (
                <></>
              )}
              {CSFGOption === true ? (
                <>
                  <Row>
                    <Form.Group controlId='name'>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type='name'
                        placeholder='Enter Name'
                        value={CSFGOptionValue}
                        onChange={(e) => setCSFGOptionValue(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <strong>
                      Please check this is correct as items cannot be refunded
                      once customised
                    </strong>
                  </Row>
                </>
              ) : (
                <p></p>
              )}
              {product.rgtiProduct && product.rgtiProduct ? (
                <Row>
                  <Form.Group controlId='namelables'>
                    <Form.Check
                      type='checkbox'
                      name='namelables'
                      label='Add Royal Greenwich Trust Initials (£3.00)'
                      style={{ marginTop: '40px' }}
                      checked={rftiOption}
                      onChange={(e) => RGTIOptipn(e.target.checked)}
                    ></Form.Check>
                  </Form.Group>
                  <strong>
                    Please check this is correct as items cannot be refunded
                    once customised
                  </strong>
                </Row>
              ) : (
                <></>
              )}
              {rftiOption === true ? (
                <>
                  <Row>
                    <Form.Group controlId='name'>
                      <Form.Label>Initials</Form.Label>
                      <Form.Control
                        type='name'
                        placeholder='Enter Initials'
                        value={rftiOptionValue}
                        onChange={(e) => setrftiOptionValue(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <strong>
                      Please check this is correct as items cannot be refunded
                      once customised
                    </strong>
                  </Row>
                </>
              ) : (
                <p></p>
              )}
            </Col>
            <Col md={3}>
              {userInfo && userInfo.isAdmin && (
                <Card className='my-4'>
                  <ListGroup variant='flush'>
                    <ListGroup.Item>
                      <Row>
                        <a
                          Style='width: 100%'
                          href={`/admin/product/${productId}/edit`}
                        >
                          <Button className='btn-block' type='button'>
                            Edit Product
                          </Button>
                        </a>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              )}
              <Card>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <Row>
                      <Col>Price:</Col>
                      <Col>
                        <strong>£{price.toFixed(2)}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  {countInStock > 0 && (
                    <ListGroup.Item>
                      <Row>
                        <Col>Qty</Col>
                        <Col>
                          {useStockSystem === true ? (
                            <Form.Control
                              as='select'
                              value={qty}
                              onChange={(e) => setQty(e.target.value)}
                            >
                              {[...Array(countInStock).keys()].map((x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              ))}
                            </Form.Control>
                          ) : (
                            <Form.Control
                              as='input'
                              type='number'
                              min='0'
                              value={qty}
                              onChange={(e) => setQty(e.target.value)}
                            ></Form.Control>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                  {showVariants()}
                  <ListGroup.Item>
                    {userInfo ? (
                      <Button
                        onClick={addtoCart}
                        className='btn-block'
                        type='button'
                        disabled={countInStock === 0}
                      >
                        Add to cart
                      </Button>
                    ) : (
                      <p>
                        Please <Link to={'/login'}>log in</Link> or{' '}
                        <Link to={'/register'}>create an account</Link>
                      </p>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col>
              <h4>Related Products</h4>
            </Col>
          </Row>
          <Row>
            {products &&
              products.map((rproduct) => (
                <Col
                  className='align-items-stretch d-flex'
                  key={rproduct._id}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={3}
                >
                  <Product product={rproduct} />
                </Col>
              ))}
          </Row>
        </>
      )}
    </>
  )
}

export default ProductScreen
