import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const SettingsHome = () => {
  return (
    <Container>
      <Row className='my-5'>
        {/* <Col>
          <Link to='/admin/settings/homepageCategories'>
            <Row>
              <Col>
                <div className='text-center'>
                  <i class='fas fa-cog fa-5x'></i>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='text-center'>
                  <span>Home Page Categories</span>
                </div>
              </Col>
            </Row>
          </Link>
        </Col> */}
        <Col>
          <Link to='/admin/settings/carousel-images'>
            <Row>
              <Col>
                <div className='text-center'>
                  <i class='fas fa-images fa-5x'></i>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='text-center'>
                  <span>Home Page Carousel Images</span>
                </div>
              </Col>
            </Row>
          </Link>
        </Col>
        <Col>
          <Link to='/admin/settings/privacypolicy'>
            <Row>
              <Col>
                <div className='text-center'>
                  <i class='fas fa-file fa-5x'></i>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='text-center'>
                  <span>Edit privacy Policy page </span>
                </div>
              </Col>
            </Row>
          </Link>
        </Col>
      </Row>
      <Row className='my-5'>
        <Col>
          <Link to='/admin/settings/deliveryinformation'>
            <Row>
              <Col>
                <div className='text-center'>
                  <i class='fas fa-file fa-5x'></i>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='text-center'>
                  <span>Edit delivery information page </span>
                </div>
              </Col>
            </Row>
          </Link>
        </Col>
        <Col>
          <Link to='/admin/settings/returnspolicy'>
            <Row>
              <Col>
                <div className='text-center'>
                  <i class='fas fa-file fa-5x'></i>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='text-center'>
                  <span>Edit returns policy page </span>
                </div>
              </Col>
            </Row>
          </Link>
        </Col>
        <Col>
          <Link to='/admin/settings/termsconditions'>
            <Row>
              <Col>
                <div className='text-center'>
                  <i class='fas fa-file fa-5x'></i>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='text-center'>
                  <span>Edit terms and conditions page </span>
                </div>
              </Col>
            </Row>
          </Link>
        </Col>
      </Row>
      {/* <Row className='my-3'>
        <Col>1 of 3</Col>
        <Col>2 of 3</Col>
        <Col>3 of 3</Col>
      </Row> */}
    </Container>
  )
}

export default SettingsHome
