import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Row, Col, ListGroup, Image, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { updateAssignee } from '../../redux/actions/orderActions'
import { listAdminUsers } from '../../redux/actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import Review from './Review'

const OrderQuickView = ({ order }) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => setShow(true)

  useEffect(() => {
    if (show) {
      dispatch(listAdminUsers())
      setAssginOrder(order.assignee)
    }
  }, [show])

  const adminUsers = useSelector((state) => state.adminUsers)
  const { users } = adminUsers

  const [assignOrder, setAssginOrder] = useState()

  const setAssignee = (orderID, user) => {
    dispatch(updateAssignee(orderID, user))
  }

  return (
    <>
      <i class='fas fa-eye ml-3' onClick={handleShow}></i>
      <Modal show={show} onHide={handleClose} size='lg' centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>Order Items</h2>
          <ListGroup variant='flush'>
            {order.orderItems.map((item, index) => (
              <ListGroup.Item key={index}>
                <Row>
                  <Col md={1}>
                    <Image src={item.image} alt={item.name} fluid rounded />
                  </Col>
                  <Col>
                    <Link to={`/product/${item.product}`}>{item.name}</Link>
                    <p dangerouslySetInnerHTML={{ __html: item.subtext }}></p>
                  </Col>
                  <Col md={4}>
                    {item.qty} x £{item.price.toFixed(2)} = £
                    {(item.qty * item.price).toFixed(2)}
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <ListGroup variant='flush'>
          {order.isPaid &&
                order.isDelivered  ? (
                  <ListGroup.Item>
                    <Review id={order._id} />
                  </ListGroup.Item>
                ) : (<>
                 <ListGroup.Item>
              <h2>Assign Order</h2>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <Form.Control
                    as='select'
                    value={assignOrder}
                    onChange={(e) => setAssginOrder(e.target.value)}
                  >
                    <option value='none'>None</option>
                    {users &&
                      users.map((option) => (
                        <option key={option.name} value={option._id}>
                          {option.name}
                        </option>
                      ))}
                  </Form.Control>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Button
                variant='success'
                type='button'
                className='btn btn-block'
                onClick={(e) => {
                  setAssignee(order._id, assignOrder)
                }}
              >
                Assign
              </Button>
            </ListGroup.Item></>)}
          </ListGroup>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default OrderQuickView
