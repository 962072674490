import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const ContactScreen = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <>
      <Helmet>
        <title>Contact Us | JK Clothing</title>
      </Helmet>
      <Row>
        <Col>
          <Row>
            <h1>CONTACT US</h1>
          </Row>
          <Row className='justify-content-center py-4'>
            <h2>Eltham Store</h2>
          </Row>
          <Row>
            <Col>
              <Col className='py-4'>
                <Row className='py-1 justify-content-center'>
                  148 Well Hall road
                </Row>
                <Row className='py-1 justify-content-center'>Eltham</Row>
                <Row className='py-1 justify-content-center'>SE9 6SN</Row>
                <Row className='py-1 justify-content-center'>London</Row>
              </Col>
              <Col className='py-4 justify-content-center'>
                <Row className='py-1 justify-content-center'>
                  sales@jkclothing.co.uk
                </Row>
                <Row className='py-1 justify-content-center'>0208 859 4004</Row>
              </Col>
            </Col>
            <Col>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4972.217694482466!2d0.0413516163826132!3d51.45615794971139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a90bc14fce85%3A0x9e23fb653392f332!2sJ%20K%20Clothing!5e0!3m2!1sen!2suk!4v1691854306151!5m2!1sen!2suk'
                width='600'
                height='450'
                Style={{ Border: '0' }}
                allowfullscreen=''
                loading='lazy'
                referrerpolicy='no-referrer-when-downgrade'
              ></iframe>
            </Col>
          </Row>
          <Row className='justify-content-center py-4 '>
            <h2>Blackfen Store</h2>
          </Row>
          <Row>
            <Col>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2486.409621016836!2d0.10210107700482275!3d51.45063531484812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8aeb7b62d9ee1%3A0x33e22a87414e718c!2sJK%20Clothing!5e0!3m2!1sen!2suk!4v1691854171618!5m2!1sen!2suk'
                width='600'
                height='450'
                Style={{ Border: '0' }}
                allowfullscreen=''
                loading='lazy'
                referrerpolicy='no-referrer-when-downgrade'
              ></iframe>
            </Col>
            <Col>
              <Col className='py-4'>
                <Row className='py-1 justify-content-center'>
                  14 Wellington Parade
                </Row>
                <Row className='py-1 justify-content-center'>Sidcup</Row>
                <Row className='py-1 justify-content-center'>DA15 9NB</Row>
                <Row className='py-1 justify-content-center'>London</Row>
              </Col>
              <Col className='py-4 justify-content-center'>
                <Row className='py-1 justify-content-center'>
                  blackfen@jkclothing.co.uk
                </Row>
                <Row className='py-1 justify-content-center'>0208 303 3136</Row>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default ContactScreen
