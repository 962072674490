import axios from 'axios'
import {
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_TYPE,
  CART_INCREASE_ITEM,
  CREATE_USER_CART_REQUEST,
  CREATE_USER_CART_SUCCESS,
  CREATE_USER_CART_FAIL,
  CART_ADD_ITEM_REQUEST,
  CART_ADD_ITEM_SUCCESS,
  CART_ADD_ITEM_FAIL,
  CART_REMOVE_ITEM_FAIL,
  CART_REMOVE_ITEM_SUCCESS,
  CART_REMOVE_ITEM_REQUEST,
  GET_USER_CART_REQUEST,
  GET_USER_CART_SUCCESS,
  GET_USER_CART_FAIL,
  CART_INCREASE_ITEM_REQUEST,
  CART_INCREASE_ITEM_SUCCESS,
  CART_INCREASE_ITEM_FAIL,
} from '../types'

export const createServerSideBasket = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    dispatch({ type: CREATE_USER_CART_REQUEST })
    const { data } = await axios.get('/api/cart/createbasket', config)
    dispatch({
      type: CREATE_USER_CART_SUCCESS,
      payload: data,
    })
    localStorage.setItem('cartID', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: CREATE_USER_CART_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getServerSideBasket = (cartID) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_USER_CART_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/cart/getbasket`, { cartID }, config)

    dispatch({
      type: GET_USER_CART_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_USER_CART_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addItemServerSideBasket =
  (id, qty, variantID, substringText, BasketID) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CART_ADD_ITEM_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/cart/additem`,
        { id, qty, variantID, substringText, BasketID },
        config
      )

      dispatch({
        type: CART_ADD_ITEM_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CART_ADD_ITEM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const removeItemServerSideBasket =
  (cartID, BasketID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CART_REMOVE_ITEM_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/cart/removeitem`,
        { cartID, BasketID },
        config
      )

      dispatch({
        type: CART_REMOVE_ITEM_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CART_REMOVE_ITEM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const increaseCartItemServerSideBasket =
  (cartID, BasketID, amount) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CART_INCREASE_ITEM_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/cart/increaseitem`,
        { cartID, BasketID, amount },
        config
      )

      dispatch({
        type: CART_INCREASE_ITEM_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CART_INCREASE_ITEM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const increaseCartItem = (id, amount) => (disptch, getState) => {
  const data = { id, amount }
  disptch({
    type: CART_INCREASE_ITEM,
    payload: data,
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const saveShippingAddress =
  (cartID, address) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/api/cart/updateaddress`,
      { cartID, address },
      config
    )

    dispatch({
      type: CART_SAVE_SHIPPING_ADDRESS,
      payload: data,
    })

    localStorage.setItem('shippingAddress', JSON.stringify(data))
  }

export const savePaymentMethod = (data) => (disptch) => {
  disptch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  })

  localStorage.setItem('paymentMethod', JSON.stringify(data))
}

export const SaveShippingMethod =
  (cartID, type, amount) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/api/cart/updateshipping`,
      { cartID, amount, type },
      config
    )

    dispatch({
      type: CART_SAVE_SHIPPING_TYPE,
      payload: data,
    })

    localStorage.setItem('shippingType', JSON.stringify(data))
  }
