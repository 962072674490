import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import { saveShippingAddress } from '../redux/actions/cartAction.js'
import CheckoutSteps from '../components/CheckoutSteps'

const ShippingScreen = ({ history }) => {
  const cartID = JSON.parse(localStorage.getItem('cartID'))

  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  const [address, setAddress] = useState(shippingAddress.address)
  const [city, setCity] = useState(shippingAddress.city)
  const [postcode, setPostCode] = useState(shippingAddress.postcode)
  const [country, setCountry] = useState(shippingAddress.country)
  const [number, setNumber] = useState(shippingAddress.number)

  const [invalidAddress, setInvalidAddress] = useState(false)
  const [invalidNumber, setInvalidNumber] = useState(false)

  const dispatch = useDispatch()

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(String(email).toLowerCase())
  }

  function validateUKPhoneNumber(phoneNumber) {
    const re =
      /^(\+44\s?7\d{3}|\(?07\d{3}\)?|\(?\d{5}\)?)\s?\d{3}\s?\d{3}$|^(\+44\s?7\d{9}|\d{11})$/
    return re.test(String(phoneNumber).trim())
  }

  const submitHandler = (e) => {
    e.preventDefault()
    console.log(validateUKPhoneNumber(number))
    if (validateEmail(address)) {
      setInvalidAddress(true)
    } else if (validateUKPhoneNumber(number) === false) {
      setInvalidAddress(false)
      setInvalidNumber(true)
    } else {
      dispatch(
        saveShippingAddress(cartID, {
          address,
          city,
          postcode,
          country,
          number,
        })
      )
      history.push('/shippingtypes')
    }
  }

  return (
    <>
      <CheckoutSteps step1 step2 />
      <FormContainer>
        <h1>Shipping Address</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='address'>
            <Form.Label>Address</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter address'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            ></Form.Control>
            {invalidAddress && (
              <p style={{ color: 'red', fontSize: '18px' }}>
                Please enter a vaild address{' '}
              </p>
            )}
          </Form.Group>
          <Form.Group controlId='city'>
            <Form.Label>City</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter city'
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='postcode'>
            <Form.Label>Post Code</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter postcode'
              value={postcode}
              onChange={(e) => setPostCode(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='country'>
            <Form.Label>Country</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter country'
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='country'>
            <Form.Label>Contact number</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter contact number'
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
            ></Form.Control>
            {invalidNumber && (
              <p style={{ color: 'red', fontSize: '18px' }}>
                Please enter a vaild contact number{' '}
              </p>
            )}
          </Form.Group>
          <Button type='submit' variant='primary'>
            Continue
          </Button>
        </Form>
      </FormContainer>
    </>
  )
}

export default ShippingScreen
