import axios from 'axios'
import {
  GET_SETTINGS_FAIL,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
} from '../types'

export const getSettings = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SETTINGS_REQUEST })
    const { data } = await axios.get(`/api/settings`)
    dispatch({
      type: GET_SETTINGS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_SETTINGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateReturnsPolicy =
  (returnsPolicyText) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/settings/updatereturns`,
        { returnsPolicyText },
        config
      )
      dispatch({
        type: GET_SETTINGS_SUCCESS,
        payload: data,
      })
    } catch (error) {}
  }

export const updateDeliveryInfomation =
  (deliveryInformationText) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/settings/updatedeliveryinfomation`,
        { deliveryInformationText },
        config
      )
      dispatch({
        type: GET_SETTINGS_SUCCESS,
        payload: data,
      })
    } catch (error) {}
  }

export const updateTermsConditions =
  (termsConditionsText) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/settings/updatetermsconditions`,
        { termsConditionsText },
        config
      )
      dispatch({
        type: GET_SETTINGS_SUCCESS,
        payload: data,
      })
    } catch (error) {}
  }

export const updatePrivacyPolicy =
  (privacyPolicyText) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/settings/updateprivacypolicy`,
        { privacyPolicyText },
        config
      )
      dispatch({
        type: GET_SETTINGS_SUCCESS,
        payload: data,
      })
    } catch (error) {}
  }
