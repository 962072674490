import {
  CATEGORIES_GET_BASE_REQUEST,
  CATEGORIES_GET_BASE_SUCCESS,
  CATEGORIES_GET_BASE_FAIL,
  CATEGORIES_GET_SLUG_REQUEST,
  CATEGORIES_GET_SLUG_SUCCESS,
  CATEGORIES_GET_SLUG_FAIL,
  CATEGORIES_GET_ALL_REQUEST,
  CATEGORIES_GET_ALL_SUCCESS,
  CATEGORIES_GET_ALL_FAIL,
  CATEGORIES_DELETE_REQUEST,
  CATEGORIES_DELETE_SUCCESS,
  CATEGORIES_DELETE_FAIL,
  CATEGORIES_GET_PARENTS_REQUEST,
  CATEGORIES_GET_PARENTS_SUCCESS,
  CATEGORIES_GET_PARENTS_FAIL,
  CATEGORIES_CREATE_REQUEST,
  CATEGORIES_CREATE_SUCCESS,
  CATEGORIES_CREATE_FAIL,
  CATEGORIES_CREATE_RESET,
  CATEGORIES_DETAILS_REQUEST,
  CATEGORIES_DETAILS_SUCCESS,
  CATEGORIES_DETAILS_FAIL,
  CATEGORIES_UPDATE_REQUEST,
  CATEGORIES_UPDATE_SUCCESS,
  CATEGORIES_UPDATE_FAIL,
  CATEGORIES_UPDATE_RESET,
  CATEGORIES_GET_PRODUCT_FAIL,
  CATEGORIES_GET_PRODUCT_REQUEST,
  CATEGORIES_GET_PRODUCT_SUCCESS,
} from '../types'

export const categoriesReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case CATEGORIES_GET_BASE_REQUEST:
      return { loading: true, categories: [] }
    case CATEGORIES_GET_BASE_SUCCESS:
      return { loading: false, categories: action.payload }
    case CATEGORIES_GET_BASE_FAIL:
      return { loading: false, error: action.payload }
    case CATEGORIES_GET_SLUG_REQUEST:
      return { loading: true, categories: [] }
    case CATEGORIES_GET_SLUG_SUCCESS:
      return { loading: false, categories: action.payload }
    case CATEGORIES_GET_SLUG_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const categoriesListReducer = (
  state = { categoriesList: [] },
  action
) => {
  switch (action.type) {
    case CATEGORIES_GET_ALL_REQUEST:
      return { loading: true }
    case CATEGORIES_GET_ALL_SUCCESS:
      return { loading: false, categoriesList: action.payload }
    case CATEGORIES_GET_ALL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const categoriesParentsListReducer = (
  state = { categoriesParentList: [] },
  action
) => {
  switch (action.type) {
    case CATEGORIES_GET_PARENTS_REQUEST:
      return { loading: true }
    case CATEGORIES_GET_PARENTS_SUCCESS:
      return { loading: false, categoriesParentList: action.payload }
    case CATEGORIES_GET_PARENTS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const categoriesDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORIES_DELETE_REQUEST:
      return { loading: true }
    case CATEGORIES_DELETE_SUCCESS:
      return { loading: false, success: true }
    case CATEGORIES_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const categoriesCreateReducer = (state = { category: {} }, action) => {
  switch (action.type) {
    case CATEGORIES_CREATE_REQUEST:
      return { loading: true }
    case CATEGORIES_CREATE_SUCCESS:
      return { loading: false, category: action.payload, success: true }
    case CATEGORIES_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case CATEGORIES_CREATE_RESET:
      return {
        category: {},
      }
    default:
      return state
  }
}

export const categoriesDetailsReducer = (state = { category: {} }, action) => {
  switch (action.type) {
    case CATEGORIES_DETAILS_REQUEST:
      return { loading: true, ...state }
    case CATEGORIES_DETAILS_SUCCESS:
      return { loading: false, category: action.payload }
    case CATEGORIES_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const categoriesProductReducer = (
  state = { categories: [] },
  action
) => {
  switch (action.type) {
    case CATEGORIES_GET_PRODUCT_REQUEST:
      return { loading: true, ...state }
    case CATEGORIES_GET_PRODUCT_SUCCESS:
      return { loading: false, categories: action.payload }
    case CATEGORIES_GET_PRODUCT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const categoriesUpdateReducer = (state = { category: {} }, action) => {
  switch (action.type) {
    case CATEGORIES_UPDATE_REQUEST:
      return { loading: true }
    case CATEGORIES_UPDATE_SUCCESS:
      return { loading: false, success: true, category: action.payload }
    case CATEGORIES_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case CATEGORIES_UPDATE_RESET:
      return { variant: {} }
    default:
      return state
  }
}
