import {
  GET_MEDIA_LIBRARY_FAIL,
  GET_MEDIA_LIBRARY_REQUEST,
  GET_MEDIA_LIBRARY_SUCCESS,
} from '../types'

export const GetMediaLibraryReducer = (state = { variant: {} }, action) => {
  switch (action.type) {
    case GET_MEDIA_LIBRARY_REQUEST:
      return { loading: true }
    case GET_MEDIA_LIBRARY_SUCCESS:
      return { loading: false, images: action.payload, success: true }
    case GET_MEDIA_LIBRARY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
