import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { listUsers, deleteUser } from '../../redux/actions/userActions'
import UsersTable from '../../components/dataTables/UsersTable'

const UserListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const usersList = useSelector((state) => state.usersList)
  const { loading, error, users } = usersList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDelete = useSelector((state) => state.userDelete)
  const { success: deleteSuccess } = userDelete

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listUsers())
    } else {
      history.push('/')
    }
  }, [dispatch, userInfo, history, deleteSuccess])

  const deleteHandler = (userId) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteUser(userId))
    }
  }

  return (
    <>
      <h1>Users</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
       <UsersTable data={users} />
      )}
    </>
  )
}

export default UserListScreen
