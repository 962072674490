import React, { useState, useEffect } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { SaveShippingMethod } from '../redux/actions/cartAction.js'

const ShippingTypeScreen = ({ history }) => {
  const cartID = JSON.parse(localStorage.getItem('cartID'))

  const serverCart = useSelector((state) => state.serverCart)
  const { cartItems: serverCartItems, loading, totalPrice } = serverCart

  const [shippingMethod, setShippingMethod] = useState('')
  const [shippingPrice, setShippingPrice] = useState(0)

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(SaveShippingMethod(cartID, shippingMethod, shippingPrice))
    history.push('/payment')
  }

  const SetShippingCost = (type) => {
    switch (type) {
      case 'Standard':
        setShippingPrice(4.99)
        setShippingMethod('Standard')
        break
      case 'Free Shipping':
        setShippingPrice(0)
        setShippingMethod('Free Shipping')
        break
      case 'Click and Collect':
        setShippingPrice(0)
        setShippingMethod('Click and Collect')
      default:
        break
    }
  }

  return (
    <>
      <CheckoutSteps step1 step2 step3 />
      <FormContainer>
        <h1>Shipping Method</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group>
            <Form.Label as='legend'>Select Method</Form.Label>
            <Col>
              {totalPrice < 75 ? (
                <Form.Check
                  type='radio'
                  label='Standard Shipping (£4.99)'
                  name='formHorizontalRadios'
                  value='Standard'
                  onChange={(e) => SetShippingCost(e.target.value)}
                />
              ) : (
                <p></p>
              )}
              {totalPrice >= 75 && (
                <Form.Check
                  type='radio'
                  label='Free Shipping (On Orders Over £75)'
                  name='formHorizontalRadios'
                  value='Free Shipping'
                  onChange={(e) => SetShippingCost(e.target.value)}
                />
              )}
              {/* <Form.Check
                type='radio'
                label='Click and Collect'
                name='formHorizontalRadios'
                value='Click and Collect'
                onChange={(e) => SetShippingCost(e.target.value)}
              /> */}
            </Col>
          </Form.Group>
          <Button
            type='submit'
            variant='primary'
            disabled={shippingMethod === ''}
          >
            Continue
          </Button>
        </Form>
      </FormContainer>
    </>
  )
}

export default ShippingTypeScreen
