import React, { useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { sendCollectionEmail } from '../../redux/actions/orderActions'

const CollectStore = ({ id }) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => setShow(true)

  const [shop, setShop] = useState('Eltham')

  const sendEmail = () => {
    dispatch(sendCollectionEmail(id, shop))
    setShow(false)
  }

  return (
    <>
      <Button type='button' className='btn btn-block' onClick={handleShow}>
        Send Collection Email
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Shop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as='select'
            value={shop}
            onChange={(e) => setShop(e.target.value)}
          >
            <option value='Eltham'>Eltham</option>
            <option value='Blackfen'>Blackfen</option>
          </Form.Control>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='success'
            type='button'
            className='btn btn-block'
            onClick={(e) => {
              sendEmail()
            }}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CollectStore
