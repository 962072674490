import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Button, Row, Col, ListGroup, Image, Card, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
  updateAssignee,
} from '../redux/actions/orderActions'
import Message from '../components/Message'
import { ORDER_PAY_RESET, ORDER_DELIEVER_RESET } from '../redux/types'
import { listAdminUsers } from '../redux/actions/userActions'
import ServerSidePayPal from '../components/PayPalButtons.js'
import CollectStore from '../components/modals/CollectStore.js'
import Review from '../components/modals/Review.js'

const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id
  const dispatch = useDispatch()

  const [sdkReady, setSdkReady] = useState(false)

  const [assignOrder, setAssginOrder] = useState()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay } = orderPay

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const adminUsers = useSelector((state) => state.adminUsers)
  const { users } = adminUsers

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }

    if (userInfo && userInfo.isAdmin) {
      dispatch(listAdminUsers())
    }

    const addPayPalScript = async () => {
      const { data: clientId } = await axios.get('/api/config/paypal')
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=GBP&locale=en_GB`
      script.async = true
      script.onload = () => {
        setSdkReady(true)
      }
      document.body.appendChild(script)
    }

    if (!order || successPay || order._id !== orderId || successDeliver) {
      dispatch({ type: ORDER_PAY_RESET })
      dispatch({ type: ORDER_DELIEVER_RESET })
      dispatch(getOrderDetails(orderId))
    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript()
      } else {
        setSdkReady(true)
      }
    } else {
      setAssginOrder(order.assignee)
    }
  }, [order, orderId, successPay, successDeliver])

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult))
  }

  const setAssignee = (orderID, user) => {
    dispatch(updateAssignee(orderID, user))
    history.push('/admin/orders')
  }

  const deliverHandler = (orderID) => {
    dispatch(deliverOrder(orderID))
  }

  const dispatchedArea = () => {
    if (order.shippingType === 'Click and Collect') {
      return <CollectStore id={order._id} />
    } else {
      return (
        <Button
          type='button'
          className='btn btn-block'
          onClick={(e) => {
            deliverHandler(order._id)
          }}
        >
          Mark As Dispatched
        </Button>
      )
    }
  }

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>
      <h1>Order {order._id}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <stong>Name: </stong> {order.name.name}
              </p>
              <p>
                <stong>Email: </stong>
                <a href={`mailto:${order.name.email}`}>{order.name.email}</a>
              </p>
              <p>
                <strong>Adderess: </strong>
                {order.shippingAddress.address}, {order.shippingAddress.city},{' '}
                {order.shippingAddress.postcode},{' '}
                {order.shippingAddress.country}
              </p>
              <h4>Contact Number</h4>
              <p>
                {order.shippingAddress?.number
                  ? order.shippingAddress?.number
                  : 'none'}
              </p>
              <h4>Shipping Type</h4>
              <p>{order.shippingType}</p>
              {order.isDelivered ? (
                <Message variant='success'>
                  Dispatched on {order.deliveredAt}
                </Message>
              ) : (
                <Message variant='danger'>Not Dispatched Yet</Message>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Payment Method</h2>
              <p>
                <strong>Method: </strong>
                {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant='success'>Paid on {order.paidAt}</Message>
              ) : (
                <Message variant='danger'>Not Paid</Message>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Order Items</h2>
              {order.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ListGroup variant='flush' className='orderItems'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                          <p
                            dangerouslySetInnerHTML={{ __html: item.subtext }}
                          ></p>
                        </Col>
                        <Col md={4}>
                          {item.qty} x £{item.price.toFixed(2)} = £
                          {(item.qty * item.price).toFixed(2)}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>Order summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>£{order.itemPrice.toFixed(2)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Shipping</Col>
                  <Col>£{order.shippingprice.toFixed(2)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>£{order.totalPrice.toFixed(2)}</Col>
                </Row>
              </ListGroup.Item>
              {!order.isPaid && (
                <ListGroup.Item>
                  {loadingPay && <Loader />}
                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    <ServerSidePayPal
                      orderID={order._id}
                      successPaymentHandler={successPaymentHandler}
                    />
                  )}
                </ListGroup.Item>
              )}
              {loadingDeliver && <Loader />}
              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isDelivered && (
                  <ListGroup.Item>{dispatchedArea()}</ListGroup.Item>
                )}
              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                order.isDelivered && (
                  <ListGroup.Item>
                    <Review id={order._id} />
                  </ListGroup.Item>
                )}
            </ListGroup>
          </Card>
          {userInfo && userInfo.isAdmin && (
            <Card style={{ marginTop: '50px' }} className='assignOrderBox'>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h2>Assign Order</h2>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>
                      <Form.Control
                        as='select'
                        value={assignOrder}
                        onChange={(e) => setAssginOrder(e.target.value)}
                      >
                        <option value='none'>None</option>
                        {users &&
                          users.map((option) => (
                            <option key={option.name} value={option._id}>
                              {option.name}
                            </option>
                          ))}
                      </Form.Control>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button
                    variant='success'
                    type='button'
                    className='btn btn-block'
                    onClick={(e) => {
                      setAssignee(order._id, assignOrder)
                    }}
                  >
                    Assign
                  </Button>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          )}
        </Col>
      </Row>
    </>
  )
}

export default OrderScreen
